import { API, graphqlOperation } from "aws-amplify";
import {
  createLanguage,
  deleteLanguage,
  updateLanguage,
} from "../graphql/mutations";
import {
  getLanguage,
  listAccountLanguages,
  listLanguages,
} from "../graphql/queries";
import {
  CreateLanguageInput,
  ModelSortDirection,
  UpdateLanguageInput,
} from "../models/api";
import { Token } from "../models/pagination";

export const list = async (searchText: "", limit: number, nextToken: Token) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(listLanguages, {
        limit,
        nextToken,
        sortDirection: ModelSortDirection.DESC,
        filter: { deleted: { eq: "0" } },
      })
    );

    return result.data.listLanguages;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const get = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getLanguage, { id })
    );

    return result.data.getLanguage;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const create = async (data: CreateLanguageInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(createLanguage, { input: data })
    );

    return result.data.createLanguage;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const update = async (data: UpdateLanguageInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateLanguage, { input: data })
    );

    return result.data.updateLanguage;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const softDelete = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateLanguage, { input: { id, deleted: "1" } })
    );

    return result.data.updateLanguage;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const remove = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(deleteLanguage, { input: { id } })
    );

    return result.data.deleteLanguage;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const accounts = async (languageID: string, limit?: number) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(listAccountLanguages, {
        filter: { languageID: { eq: languageID }, deleted: { eq: "0" } },
        limit: limit ? limit : 1000,
      })
    );

    return result.data.listAccountLanguages.items;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};
