import { PropertyFor } from "../../models/api";

// actions
const SET = "propertyFor/SET";
const SET_SEARCH_TEXT = "propertyFor/SET_SEARCH_TEXT";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: PropertyFor[]) => {
  return { listing, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};
