import { useDispatch, useSelector } from "react-redux";
import {
  list,
  get,
  create,
  update,
  softDelete,
  remove,
} from "../services/feature";
import { openSnackBar } from "../store/ducks/app";
import { setListing, setFeatures, setSearchText } from "../store/ducks/feature";
import { HeadCell } from "../models/dataTable";
import { Token } from "../models/pagination";
import {
  Feature,
  CreateFeatureInput,
  UpdateFeatureInput,
} from "./../models/api";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state: any) => state.app.session);
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  const resourceListing = async (
    limit: number,
    nextToken: Token,
    setNextNextToken?: any
  ) => {
    try {
      const listing: any = await list(searchText, limit, nextToken);

      dispatch(setListing(listing.items));
      if (setNextNextToken) setNextNextToken(listing.nextToken);
    } catch (err: Error | any) {
      dispatch(openSnackBar(`Error fetching ${listingName} ${err}`, "error"));
      throw err;
    }
  };

  const accountFeatures = async () => {
    try {
      const listing: any = await list(searchText, 1000, undefined);

      return listing.items;
    } catch (err: Error | any) {
      dispatch(openSnackBar(`Error fetching ${listingName} ${err}`, "error"));
      throw err;
    }
  };

  const getResource = async (resourceId: string): Promise<any> => {
    try {
      const single: Feature =
        listing.length === 0
          ? await get(resourceId)
          : listing.filter((resource: any) => resource.id === resourceId)[0];

      return single;
    } catch (err: Error | any) {
      dispatch(openSnackBar(`Error getting ${singleName} ${err}`, "error"));
      throw err;
    }
  };

  const createResource = async (data: any, stateData: any): Promise<string> => {
    try {
      const createInput: CreateFeatureInput = {
        name: data.name,
        icon: data.icon,
        slug: data.slug,
        isDashboard: data.isDashboard ? data.isDashboard : false,
        precedence: data.precedence ? data.precedence : "a",
        deleted: "0",
        featureCreatedById: authUser.id,
      };

      const newResource = await create(createInput);

      dispatch(setListing([...listing, newResource]));

      return `New ${singleName} has been created successfully`;
    } catch (err: Error | any) {
      dispatch(openSnackBar(`Error creating ${singleName} ${err}`, "error"));
      throw err;
    }
  };

  const updateResource = async (
    resourceId: string,
    data: any,
    stateData: any
  ): Promise<string> => {
    try {
      const updateInput: UpdateFeatureInput = {
        id: resourceId,
        name: data.name,
        icon: data.icon,
        slug: data.slug,
        isDashboard: data.isDashboard,
        precedence: data.precedence,
        deleted: "0",
        createdAt: stateData.resource.createdAt,
      };

      await update(updateInput);

      return `${singleName} has been updated successfully`;
    } catch (err: Error | any) {
      console.log(err);
      dispatch(openSnackBar(`Error updating ${singleName} ${err}`, "error"));
      throw err;
    }
  };

  const trashResource = async (resourceId: string): Promise<string> => {
    try {
      await softDelete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      return `${singleName} has been moved to trash successfully`;
    } catch (err: Error | any) {
      throw new Error(err);
    }
  };

  const bulkTrashResource = async (resourceIds: any): Promise<string> => {
    for (let resourceId of resourceIds) {
      try {
        await softDelete(resourceId);
      } catch (err: Error | any) {
        dispatch(openSnackBar(`Error trashing ${singleName} ${err}`, "error"));
        throw err;
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    return `${resourceIds.size} ${listingName} items has been moved to trash`;
  };

  const deleteResource = async (resourceId: string): Promise<string> => {
    try {
      await remove(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      dispatch(openSnackBar(`Error deleting ${singleName} ${err}`, "error"));
      throw err;
    }
  };

  const resourceHeadCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "slug",
      numeric: false,
      disablePadding: false,
      label: "Slug",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const resourceDataCells: readonly string[] = ["name", "slug"];

  const selectOptions: any[] = [];

  for (let option of listing) {
    selectOptions.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api["fetchAccountFeatures"] = accountFeatures;
  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = selectOptions;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = resourceHeadCells;
  api[`${listingName}DataCells`] = resourceDataCells;
  api[`${listingName}FetchListing`] = resourceListing;
  api[`${listingName}GetResource`] = getResource;
  api[`${listingName}CreateResource`] = createResource;
  api[`${listingName}UpdateResource`] = updateResource;
  api[`${listingName}TrashResource`] = trashResource;
  api[`${listingName}BulkTrashResource`] = bulkTrashResource;
  api[`${listingName}DeleteResource`] = deleteResource;
  api[`${listingName}SearchResource`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`setAccountFeatures`] = (listing: Feature[]) =>
    dispatch(setFeatures(listing));

  return api;
};

export default useResource;
