/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:397c3acc-cced-46cf-a709-04fcd39af4f2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_j4GjQZMSy",
    "aws_user_pools_web_client_id": "6tbeglq8l8laibr1bi6d7kah3r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://15fvoatiw8.execute-api.us-east-2.amazonaws.com/clintro",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wxoau7wmabaopf5nxkqjodf2xy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "newmultisitesed36ebaed90648269357bf9cb64dc50374710-clintro",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_content_delivery_bucket": "newmultisites-20220502023403-hostingbucket-clintro",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1upbbj8h6yu5f.cloudfront.net"
};


export default awsmobile;
