import { lazy, FC, useEffect } from "react";
import Amplify from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";

import awsExports from "./aws-exports";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import ErrorBoundary from "./components/UI/ErrorBoundary";
import useTheme from "./hooks/useTheme";
import useLoading from "./hooks/useLoading";
import useFeature from "./hooks/useFeature";
import { authState } from "./services/auth";
import { get as getUser } from "./services/user";
import { clearSession, setSession } from "./store/ducks/app";
import { Pages } from "./constants/enums";
import useLanguage from "./hooks/useLanguage";
import Maintenance from "./components/UI/Maintenance";

Amplify.configure(awsExports);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const Admin = lazy(() => import("./containers/Dashboard"));
const Auth = lazy(() => import("./containers/Auth"));

const App: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const { loading, changeLoading } = useLoading();
  const { fetchAccountFeatures, setAccountFeatures } = useFeature(
    "features",
    "feature"
  );
  const { fetchAccountLanguages, setAccountLanguages } = useLanguage(
    "languages",
    "language"
  );
  const { theme } = useTheme();

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const session = await authState();
        const data = await Promise.all([
          getUser(session.sub),
          fetchAccountFeatures(),
          fetchAccountLanguages(),
        ]);

        dispatch(setSession(data[0]));
        setAccountFeatures(data[1]);
        setAccountLanguages(data[2]);
        changeLoading(false);
      } catch (error) {
        dispatch(clearSession());
        changeLoading(false);
        navigate(Pages.LOGIN);
      }
    };

    fetchUserSession();
    // eslint-disable-next-line
  }, []);

  if (loading) return <MainLoader />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ".MuiSnackbar-root": { right: "24px !important" },
          ".MuiPaper-root-MuiSnackbarContent-root": {
            width: "100%",
          },
          ".MuiDrawer-paper": {
            borderRight: "none !important",
          },
          ".tox-tinymce": {
            borderWidth: "1px !important",
            borderRadius: "0px !important",
          },
          "#editor textarea": {
            display: "none",
          },
        }}
      />
      <ErrorBoundary>
        {/* <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="dashboard/*" element={<Admin />} />
          </Routes>
        </Suspense> */}
        <Maintenance />
        {modalOpen && <MainModal />}
        {snackBarOpen && <MainSnackbar />}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
