import { Category, PostCategory } from "../../models/api";

// actions
const SET = "postsCategories/SET";
const SET_BY_POST = "postsCategories/SET_BY_POST";
const SET_SEARCH_TEXT = "postsCategories/SET_SEARCH_TEXT";

const DEFAULT_STATE = {
  listing: [],
  byPost: [],
  searchText: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_BY_POST:
      return Object.assign({}, state, { byPost: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: Category[]) => ({ listing, type: SET });
export const setListingByPost = (listing: PostCategory[]) => ({
  listing,
  type: SET_BY_POST,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
