export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum Pages {
  LOGIN = "/",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  USERS = "users",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "faqs",
  QUESTIONS_TYPES = "questions-types",
  PROPERTIES = "properties",
  PROPERTY_TYPES = "property-types",
  PROPERTY_FOR = "property-for",
  PROPERTY_FEATURES = "property-features",
  PROJECTS = "projects",
  SERVICES = "services",
  CATEGORIES = "categories",
  POSTS_CATEGORIES = "posts-categories",
  CITIES = "cities",
  AREAS = "neighborhoods",
  AMENITIES = "amenities",
  MATERIALS = "materials",
  UNIT_TYPES = "unit-types",
  ABOUT_US = "about-us",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}
