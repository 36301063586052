import { Feature } from "../../models/api";

// actions
const SET = "features/SET";
const SET_FEATURES = "features/SET_FEATURES";
const SET_SEARCH_TEXT = "features/SET_SEARCH_TEXT";

const DEFAULT_STATE = {
  listing: [],
  features: [],
  searchText: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_FEATURES:
      return Object.assign({}, state, { features: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: Feature[]) => ({ listing, type: SET });
export const setFeatures = (listing: Feature[]) => ({
  listing,
  type: SET_FEATURES,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
