import { Media } from "../../models/api";

// actions
const SET = "media/SET";
const SET_SELECTED = "media/SET_SELECTED";
const SET_SEARCH_TEXT = "media/SET_SEARCH_TEXT";
const SET_ATTACHMENTS = "media/SET_ATTACHMENTS";

const DEFAULT_STATE = {
  listing: [],
  attachments: [],
  searchText: "",
  selected: new Set(),
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.files });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_ATTACHMENTS:
      return Object.assign({}, state, { attachments: action.payload });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: Media[]) => ({ listing, type: SET });
export const setSelected = (files: any) => ({ files, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setAttachments = (payload: string) => ({
  payload,
  type: SET_ATTACHMENTS,
});
