import { Language } from "../../models/api";

// actions
const SET = "languages/SET";
const SET_LANGUAGES = "languages/SET_LANGUAGES";
const SET_SEARCH_TEXT = "languages/SET_SEARCH_TEXT";

const DEFAULT_STATE = {
  listing: [],
  languages: [],
  searchText: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_LANGUAGES:
      return Object.assign({}, state, { languages: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: Language[]) => ({ listing, type: SET });
export const setLanguages = (listing: Language[]) => ({
  listing,
  type: SET_LANGUAGES,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
