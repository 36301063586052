/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  username: string,
  preferred_username: string,
  email: string,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  group?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  courses?: ModelCourseStudentConnection | null,
  username: string,
  preferred_username: string,
  email: string,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
};

export type ModelCourseStudentConnection = {
  __typename: "ModelCourseStudentConnection",
  items:  Array<CourseStudent | null >,
  nextToken?: string | null,
};

export type CourseStudent = {
  __typename: "CourseStudent",
  id: string,
  userID: string,
  courseID: string,
  user: User,
  course: Course,
  createdAt: string,
  updatedAt: string,
};

export type Course = {
  __typename: "Course",
  id: string,
  name: string,
  slug: string,
  description?: string | null,
  image?: Attachment | null,
  modules?: ModelModuleConnection | null,
  students?: ModelCourseStudentConnection | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  fileurl: string,
  alternativeText?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  mediaAttachmentsId?: string | null,
  propertyGalleryId?: string | null,
  projectExteriorsId?: string | null,
  projectInteriorsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type ModelModuleConnection = {
  __typename: "ModelModuleConnection",
  items:  Array<Module | null >,
  nextToken?: string | null,
};

export type Module = {
  __typename: "Module",
  id: string,
  name: string,
  parentId?: string | null,
  content: string,
  precedence?: string | null,
  image?: Attachment | null,
  quizzes?: ModelQuizConnection | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  vrContent?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type ModelQuizConnection = {
  __typename: "ModelQuizConnection",
  items:  Array<Quiz | null >,
  nextToken?: string | null,
};

export type Quiz = {
  __typename: "Quiz",
  id: string,
  title: string,
  slug?: string | null,
  description?: string | null,
  type?: QuizType | null,
  questions?: ModelQuizQuestionConnection | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type QuizType = {
  __typename: "QuizType",
  id: string,
  name: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  quizTypeCreatedById?: string | null,
};

export type ModelQuizQuestionConnection = {
  __typename: "ModelQuizQuestionConnection",
  items:  Array<QuizQuestion | null >,
  nextToken?: string | null,
};

export type QuizQuestion = {
  __typename: "QuizQuestion",
  id: string,
  quizID: string,
  questionID: string,
  quiz: Quiz,
  question: Question,
  createdAt: string,
  updatedAt: string,
};

export type Question = {
  __typename: "Question",
  id: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  answer: string,
  arAnswer?: string | null,
  frAnswer?: string | null,
  rsAnswer?: string | null,
  type?: QuestionType | null,
  options?: Array< string | null > | null,
  image?: Attachment | null,
  quizzes?: ModelQuizQuestionConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type QuestionType = {
  __typename: "QuestionType",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  questionTypeCreatedById?: string | null,
};

export type UpdateUserInput = {
  id: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateEmailVerificationInput = {
  id?: string | null,
  name: string,
  email: string,
  verification_code: string,
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelEmailVerificationConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationConditionInput | null > | null,
  or?: Array< ModelEmailVerificationConditionInput | null > | null,
  not?: ModelEmailVerificationConditionInput | null,
};

export type EmailVerification = {
  __typename: "EmailVerification",
  id: string,
  name: string,
  email: string,
  verification_code: string,
  createdAt: string,
  deleted?: string | null,
  updatedAt: string,
};

export type UpdateEmailVerificationInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  verification_code?: string | null,
  createdAt?: string | null,
  deleted?: string | null,
};

export type DeleteEmailVerificationInput = {
  id: string,
};

export type CreateAccountInput = {
  id?: string | null,
  domain: string,
  siteTitle: string,
  arSiteTitle: string,
  frSiteTitle: string,
  rsSiteTitle: string,
  tagline: string,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description: string,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  workingHours?: string | null,
  arWorkingHours?: string | null,
  frWorkingHours?: string | null,
  rsWorkingHours?: string | null,
  email: string,
  phone_number: string,
  siteAddress: string,
  defaultLanguage: string,
  status: string,
  deleted?: string | null,
  createdAt?: string | null,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type ModelAccountConditionInput = {
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  arSiteTitle?: ModelStringInput | null,
  frSiteTitle?: ModelStringInput | null,
  rsSiteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  arTagline?: ModelStringInput | null,
  frTagline?: ModelStringInput | null,
  rsTagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  rsAddress?: ModelStringInput | null,
  workingHours?: ModelStringInput | null,
  arWorkingHours?: ModelStringInput | null,
  frWorkingHours?: ModelStringInput | null,
  rsWorkingHours?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  accountLogoId?: ModelIDInput | null,
  accountCreatedById?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  domain: string,
  logo?: Attachment | null,
  siteTitle: string,
  arSiteTitle: string,
  frSiteTitle: string,
  rsSiteTitle: string,
  tagline: string,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description: string,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  workingHours?: string | null,
  arWorkingHours?: string | null,
  frWorkingHours?: string | null,
  rsWorkingHours?: string | null,
  email: string,
  phone_number: string,
  siteAddress: string,
  defaultLanguage: string,
  languages?: ModelAccountLanguageConnection | null,
  status: string,
  socialLinks?: ModelSocialLinkConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type ModelAccountLanguageConnection = {
  __typename: "ModelAccountLanguageConnection",
  items:  Array<AccountLanguage | null >,
  nextToken?: string | null,
};

export type AccountLanguage = {
  __typename: "AccountLanguage",
  id: string,
  accountID: string,
  languageID: string,
  account: Account,
  language: Language,
  createdAt: string,
  updatedAt: string,
};

export type Language = {
  __typename: "Language",
  id: string,
  name: string,
  code: string,
  accounts?: ModelAccountLanguageConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  languageCreatedById?: string | null,
};

export type ModelSocialLinkConnection = {
  __typename: "ModelSocialLinkConnection",
  items:  Array<SocialLink | null >,
  nextToken?: string | null,
};

export type SocialLink = {
  __typename: "SocialLink",
  id: string,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type UpdateAccountInput = {
  id: string,
  domain?: string | null,
  siteTitle?: string | null,
  arSiteTitle?: string | null,
  frSiteTitle?: string | null,
  rsSiteTitle?: string | null,
  tagline?: string | null,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address?: string | null,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  workingHours?: string | null,
  arWorkingHours?: string | null,
  frWorkingHours?: string | null,
  rsWorkingHours?: string | null,
  email?: string | null,
  phone_number?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  status?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateSocialLinkInput = {
  id?: string | null,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type ModelSocialLinkConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSocialLinkConditionInput | null > | null,
  or?: Array< ModelSocialLinkConditionInput | null > | null,
  not?: ModelSocialLinkConditionInput | null,
  accountSocialLinksId?: ModelIDInput | null,
  socialLinkCreatedById?: ModelIDInput | null,
};

export type UpdateSocialLinkInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type DeleteSocialLinkInput = {
  id: string,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon: string,
  slug?: string | null,
  isDashboard: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  featureCreatedById?: string | null,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  isDashboard?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  featureCreatedById?: ModelIDInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon: string,
  slug?: string | null,
  isDashboard: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  featureCreatedById?: string | null,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  isDashboard?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  featureCreatedById?: string | null,
};

export type DeleteFeatureInput = {
  id: string,
};

export type CreateLanguageInput = {
  id?: string | null,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt?: string | null,
  languageCreatedById?: string | null,
};

export type ModelLanguageConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLanguageConditionInput | null > | null,
  or?: Array< ModelLanguageConditionInput | null > | null,
  not?: ModelLanguageConditionInput | null,
  languageCreatedById?: ModelIDInput | null,
};

export type UpdateLanguageInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  languageCreatedById?: string | null,
};

export type DeleteLanguageInput = {
  id: string,
};

export type CreateMediaInput = {
  id?: string | null,
  fileurl: string,
  filename: string,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  mediaCreatedById?: string | null,
};

export type ModelMediaConditionInput = {
  fileurl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  filesize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
  mediaCreatedById?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  fileurl: string,
  filename: string,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: ModelAttachmentConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  mediaCreatedById?: string | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
};

export type UpdateMediaInput = {
  id: string,
  fileurl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  mediaCreatedById?: string | null,
};

export type DeleteMediaInput = {
  id: string,
};

export type CreateAttachmentInput = {
  id?: string | null,
  fileurl: string,
  alternativeText?: string | null,
  createdAt?: string | null,
  mediaAttachmentsId?: string | null,
  propertyGalleryId?: string | null,
  projectExteriorsId?: string | null,
  projectInteriorsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type ModelAttachmentConditionInput = {
  fileurl?: ModelStringInput | null,
  alternativeText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  mediaAttachmentsId?: ModelIDInput | null,
  propertyGalleryId?: ModelIDInput | null,
  projectExteriorsId?: ModelIDInput | null,
  projectInteriorsId?: ModelIDInput | null,
  attachmentCreatedById?: ModelIDInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  fileurl?: string | null,
  alternativeText?: string | null,
  createdAt?: string | null,
  mediaAttachmentsId?: string | null,
  propertyGalleryId?: string | null,
  projectExteriorsId?: string | null,
  projectInteriorsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type DeleteAttachmentInput = {
  id: string,
};

export type CreateMenuInput = {
  id?: string | null,
  name: string,
  locations: Array< string | null >,
  deleted?: string | null,
  createdAt?: string | null,
  menuCreatedById?: string | null,
};

export type ModelMenuConditionInput = {
  name?: ModelStringInput | null,
  locations?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuConditionInput | null > | null,
  or?: Array< ModelMenuConditionInput | null > | null,
  not?: ModelMenuConditionInput | null,
  menuCreatedById?: ModelIDInput | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  name: string,
  locations: Array< string | null >,
  items?: ModelMenuItemConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  menuCreatedById?: string | null,
};

export type ModelMenuItemConnection = {
  __typename: "ModelMenuItemConnection",
  items:  Array<MenuItem | null >,
  nextToken?: string | null,
};

export type MenuItem = {
  __typename: "MenuItem",
  id: string,
  type: string,
  page?: Page | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  parentId?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  history?: string | null,
  arHistory?: string | null,
  frHistory?: string | null,
  rsHistory?: string | null,
  values?: string | null,
  arValues?: string | null,
  frValues?: string | null,
  rsValues?: string | null,
  excerpt?: string | null,
  status?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type UpdateMenuInput = {
  id: string,
  name?: string | null,
  locations?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuCreatedById?: string | null,
};

export type DeleteMenuInput = {
  id: string,
};

export type CreateMenuItemInput = {
  id?: string | null,
  type: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type ModelMenuItemConditionInput = {
  type?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuItemConditionInput | null > | null,
  or?: Array< ModelMenuItemConditionInput | null > | null,
  not?: ModelMenuItemConditionInput | null,
  menuItemsId?: ModelIDInput | null,
  menuItemPageId?: ModelIDInput | null,
  menuItemCreatedById?: ModelIDInput | null,
};

export type UpdateMenuItemInput = {
  id: string,
  type?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type DeleteMenuItemInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  parentId?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  history?: string | null,
  arHistory?: string | null,
  frHistory?: string | null,
  rsHistory?: string | null,
  values?: string | null,
  arValues?: string | null,
  frValues?: string | null,
  rsValues?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type ModelPageConditionInput = {
  parentId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  history?: ModelStringInput | null,
  arHistory?: ModelStringInput | null,
  frHistory?: ModelStringInput | null,
  rsHistory?: ModelStringInput | null,
  values?: ModelStringInput | null,
  arValues?: ModelStringInput | null,
  frValues?: ModelStringInput | null,
  rsValues?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
  pageThumbnailId?: ModelIDInput | null,
  pageCreatedById?: ModelIDInput | null,
};

export type UpdatePageInput = {
  id: string,
  parentId?: string | null,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug?: string | null,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  history?: string | null,
  arHistory?: string | null,
  frHistory?: string | null,
  rsHistory?: string | null,
  values?: string | null,
  arValues?: string | null,
  frValues?: string | null,
  rsValues?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  type: string,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type ModelCategoryConditionInput = {
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  categoryThumbnailId?: ModelIDInput | null,
  categoryCreatedById?: ModelIDInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  type: string,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  posts?: ModelPostCategoryConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type ModelPostCategoryConnection = {
  __typename: "ModelPostCategoryConnection",
  items:  Array<PostCategory | null >,
  nextToken?: string | null,
};

export type PostCategory = {
  __typename: "PostCategory",
  id: string,
  categoryID: string,
  postID: string,
  category: Category,
  post: Post,
  createdAt: string,
  updatedAt: string,
};

export type Post = {
  __typename: "Post",
  id: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  excerpt?: string | null,
  status?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  categories?: ModelPostCategoryConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug?: string | null,
  type?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type ModelPostConditionInput = {
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  postThumbnailId?: ModelIDInput | null,
  postCreatedById?: ModelIDInput | null,
};

export type UpdatePostInput = {
  id: string,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug?: string | null,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateTestimonialInput = {
  id?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  testimonialThumbnailId?: string | null,
  testimonialCreatedById?: string | null,
};

export type ModelTestimonialConditionInput = {
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTestimonialConditionInput | null > | null,
  or?: Array< ModelTestimonialConditionInput | null > | null,
  not?: ModelTestimonialConditionInput | null,
  testimonialThumbnailId?: ModelIDInput | null,
  testimonialCreatedById?: ModelIDInput | null,
};

export type Testimonial = {
  __typename: "Testimonial",
  id: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug: string,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  testimonialThumbnailId?: string | null,
  testimonialCreatedById?: string | null,
};

export type UpdateTestimonialInput = {
  id: string,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  slug?: string | null,
  content?: string | null,
  arContent?: string | null,
  frContent?: string | null,
  rsContent?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  testimonialThumbnailId?: string | null,
  testimonialCreatedById?: string | null,
};

export type DeleteTestimonialInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  name: string,
  email: string,
  subject?: string | null,
  message: string,
  deleted?: string | null,
  createdAt?: string | null,
};

export type ModelMessageConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  name: string,
  email: string,
  subject?: string | null,
  message: string,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMessageInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  subject?: string | null,
  message?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateSlideInput = {
  id?: string | null,
  title: string,
  precedence: string,
  content?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type ModelSlideConditionInput = {
  title?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSlideConditionInput | null > | null,
  or?: Array< ModelSlideConditionInput | null > | null,
  not?: ModelSlideConditionInput | null,
  slideImageId?: ModelIDInput | null,
  slideMobImageId?: ModelIDInput | null,
  slideCreatedById?: ModelIDInput | null,
};

export type Slide = {
  __typename: "Slide",
  id: string,
  title: string,
  precedence: string,
  content?: string | null,
  image?: Attachment | null,
  mobImage?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type UpdateSlideInput = {
  id: string,
  title?: string | null,
  precedence?: string | null,
  content?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type DeleteSlideInput = {
  id: string,
};

export type CreateCourseInput = {
  id?: string | null,
  name: string,
  slug: string,
  description?: string | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type ModelCourseConditionInput = {
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisiteQuiz?: ModelStringInput | null,
  prerequisiteQuizTitle?: ModelStringInput | null,
  prerequisiteQuizSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  prerequisiteModuleId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
  courseImageId?: ModelIDInput | null,
  courseCreatedById?: ModelIDInput | null,
};

export type UpdateCourseInput = {
  id: string,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type DeleteCourseInput = {
  id: string,
};

export type CreateModuleInput = {
  id?: string | null,
  name: string,
  parentId?: string | null,
  content: string,
  precedence?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type ModelModuleConditionInput = {
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  afterModuleQuiz?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextModule?: ModelStringInput | null,
  nextModuleTitle?: ModelStringInput | null,
  nextModuleId?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleConditionInput | null > | null,
  or?: Array< ModelModuleConditionInput | null > | null,
  not?: ModelModuleConditionInput | null,
  courseModulesId?: ModelIDInput | null,
  moduleImageId?: ModelIDInput | null,
  moduleVrContentId?: ModelIDInput | null,
  moduleCreatedById?: ModelIDInput | null,
};

export type UpdateModuleInput = {
  id: string,
  name?: string | null,
  parentId?: string | null,
  content?: string | null,
  precedence?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type DeleteModuleInput = {
  id: string,
};

export type CreateModuleStatusInput = {
  id?: string | null,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt?: string | null,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type ModelModuleStatusConditionInput = {
  deleted?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleStatusConditionInput | null > | null,
  or?: Array< ModelModuleStatusConditionInput | null > | null,
  not?: ModelModuleStatusConditionInput | null,
  moduleStatusModuleId?: ModelIDInput | null,
  moduleStatusStudentId?: ModelIDInput | null,
};

export type ModuleStatus = {
  __typename: "ModuleStatus",
  id: string,
  module: Module,
  student: User,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type UpdateModuleStatusInput = {
  id: string,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt?: string | null,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type DeleteModuleStatusInput = {
  id: string,
};

export type CreateQuizInput = {
  id?: string | null,
  title: string,
  slug?: string | null,
  description?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type ModelQuizConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  prerequisiteSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizConditionInput | null > | null,
  or?: Array< ModelQuizConditionInput | null > | null,
  not?: ModelQuizConditionInput | null,
  moduleQuizzesId?: ModelIDInput | null,
  quizTypeId?: ModelIDInput | null,
  quizCreatedById?: ModelIDInput | null,
};

export type UpdateQuizInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  description?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type DeleteQuizInput = {
  id: string,
};

export type CreateQuizTypeInput = {
  id?: string | null,
  name: string,
  deleted?: string | null,
  createdAt?: string | null,
  quizTypeCreatedById?: string | null,
};

export type ModelQuizTypeConditionInput = {
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizTypeConditionInput | null > | null,
  or?: Array< ModelQuizTypeConditionInput | null > | null,
  not?: ModelQuizTypeConditionInput | null,
  quizTypeCreatedById?: ModelIDInput | null,
};

export type UpdateQuizTypeInput = {
  id: string,
  name?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  quizTypeCreatedById?: string | null,
};

export type DeleteQuizTypeInput = {
  id: string,
};

export type CreateQuizStatusInput = {
  id?: string | null,
  isCompleted: boolean,
  totalScore: number,
  studentScore: number,
  deleted?: string | null,
  createdAt?: string | null,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type ModelQuizStatusConditionInput = {
  isCompleted?: ModelBooleanInput | null,
  totalScore?: ModelIntInput | null,
  studentScore?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizStatusConditionInput | null > | null,
  or?: Array< ModelQuizStatusConditionInput | null > | null,
  not?: ModelQuizStatusConditionInput | null,
  quizStatusQuizId?: ModelIDInput | null,
  quizStatusStudentId?: ModelIDInput | null,
};

export type QuizStatus = {
  __typename: "QuizStatus",
  id: string,
  quiz: Quiz,
  student: User,
  isCompleted: boolean,
  totalScore: number,
  studentScore: number,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type UpdateQuizStatusInput = {
  id: string,
  isCompleted?: boolean | null,
  totalScore?: number | null,
  studentScore?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type DeleteQuizStatusInput = {
  id: string,
};

export type CreateQuestionTypeInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeCreatedById?: string | null,
};

export type ModelQuestionTypeConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionTypeConditionInput | null > | null,
  or?: Array< ModelQuestionTypeConditionInput | null > | null,
  not?: ModelQuestionTypeConditionInput | null,
  questionTypeCreatedById?: ModelIDInput | null,
};

export type UpdateQuestionTypeInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeCreatedById?: string | null,
};

export type DeleteQuestionTypeInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  answer: string,
  arAnswer?: string | null,
  frAnswer?: string | null,
  rsAnswer?: string | null,
  options?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type ModelQuestionConditionInput = {
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  arAnswer?: ModelStringInput | null,
  frAnswer?: ModelStringInput | null,
  rsAnswer?: ModelStringInput | null,
  options?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  questionTypeId?: ModelIDInput | null,
  questionImageId?: ModelIDInput | null,
  questionCreatedById?: ModelIDInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  answer?: string | null,
  arAnswer?: string | null,
  frAnswer?: string | null,
  rsAnswer?: string | null,
  options?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateAnswerInput = {
  id?: string | null,
  values: Array< string | null >,
  deleted?: string | null,
  isCorrect: boolean,
  createdAt?: string | null,
  answerQuestionId: string,
  answerStudentId: string,
};

export type ModelAnswerConditionInput = {
  values?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  isCorrect?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnswerConditionInput | null > | null,
  or?: Array< ModelAnswerConditionInput | null > | null,
  not?: ModelAnswerConditionInput | null,
  answerQuestionId?: ModelIDInput | null,
  answerStudentId?: ModelIDInput | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  question: Question,
  student: User,
  values: Array< string | null >,
  deleted?: string | null,
  isCorrect: boolean,
  createdAt: string,
  updatedAt: string,
  answerQuestionId: string,
  answerStudentId: string,
};

export type UpdateAnswerInput = {
  id: string,
  values?: Array< string | null > | null,
  deleted?: string | null,
  isCorrect?: boolean | null,
  createdAt?: string | null,
  answerQuestionId: string,
  answerStudentId: string,
};

export type DeleteAnswerInput = {
  id: string,
};

export type CreatePropertyInput = {
  id?: string | null,
  slug: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  projectSlug?: string | null,
  areaSlug?: string | null,
  baths: number,
  beds: number,
  area: number,
  propertyFor?: string | null,
  type?: string | null,
  city?: string | null,
  featured?: boolean | null,
  garage?: number | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  price?: number | null,
  garageSize?: number | null,
  propertyID?: string | null,
  video?: string | null,
  features?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyImageId?: string | null,
  propertyVirtualImageId?: string | null,
  propertyCreatedById?: string | null,
};

export type ModelPropertyConditionInput = {
  slug?: ModelStringInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  rsAddress?: ModelStringInput | null,
  projectSlug?: ModelStringInput | null,
  areaSlug?: ModelStringInput | null,
  baths?: ModelIntInput | null,
  beds?: ModelIntInput | null,
  area?: ModelIntInput | null,
  propertyFor?: ModelStringInput | null,
  type?: ModelStringInput | null,
  city?: ModelStringInput | null,
  featured?: ModelBooleanInput | null,
  garage?: ModelIntInput | null,
  yearBuilt?: ModelIntInput | null,
  zipCode?: ModelStringInput | null,
  price?: ModelIntInput | null,
  garageSize?: ModelIntInput | null,
  propertyID?: ModelStringInput | null,
  video?: ModelStringInput | null,
  features?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyConditionInput | null > | null,
  or?: Array< ModelPropertyConditionInput | null > | null,
  not?: ModelPropertyConditionInput | null,
  propertyImageId?: ModelIDInput | null,
  propertyVirtualImageId?: ModelIDInput | null,
  propertyCreatedById?: ModelIDInput | null,
};

export type Property = {
  __typename: "Property",
  id: string,
  slug: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  projectSlug?: string | null,
  areaSlug?: string | null,
  baths: number,
  beds: number,
  area: number,
  image?: Attachment | null,
  virtualImage?: Attachment | null,
  propertyFor?: string | null,
  type?: string | null,
  city?: string | null,
  gallery?: ModelAttachmentConnection | null,
  featured?: boolean | null,
  garage?: number | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  price?: number | null,
  garageSize?: number | null,
  propertyID?: string | null,
  video?: string | null,
  features?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  propertyImageId?: string | null,
  propertyVirtualImageId?: string | null,
  propertyCreatedById?: string | null,
};

export type UpdatePropertyInput = {
  id: string,
  slug?: string | null,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  address?: string | null,
  arAddress?: string | null,
  frAddress?: string | null,
  rsAddress?: string | null,
  projectSlug?: string | null,
  areaSlug?: string | null,
  baths?: number | null,
  beds?: number | null,
  area?: number | null,
  propertyFor?: string | null,
  type?: string | null,
  city?: string | null,
  featured?: boolean | null,
  garage?: number | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  price?: number | null,
  garageSize?: number | null,
  propertyID?: string | null,
  video?: string | null,
  features?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyImageId?: string | null,
  propertyVirtualImageId?: string | null,
  propertyCreatedById?: string | null,
};

export type DeletePropertyInput = {
  id: string,
};

export type CreatePropertyTypeInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  parentId?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyTypeImageId?: string | null,
  propertyTypeCreatedById?: string | null,
};

export type ModelPropertyTypeConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyTypeConditionInput | null > | null,
  or?: Array< ModelPropertyTypeConditionInput | null > | null,
  not?: ModelPropertyTypeConditionInput | null,
  propertyTypeImageId?: ModelIDInput | null,
  propertyTypeCreatedById?: ModelIDInput | null,
};

export type PropertyType = {
  __typename: "PropertyType",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  parentId?: string | null,
  image?: Attachment | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  propertyTypeImageId?: string | null,
  propertyTypeCreatedById?: string | null,
};

export type UpdatePropertyTypeInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  parentId?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyTypeImageId?: string | null,
  propertyTypeCreatedById?: string | null,
};

export type DeletePropertyTypeInput = {
  id: string,
};

export type CreatePropertyStatusInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  propertyStatusCreatedById?: string | null,
};

export type ModelPropertyStatusConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyStatusConditionInput | null > | null,
  or?: Array< ModelPropertyStatusConditionInput | null > | null,
  not?: ModelPropertyStatusConditionInput | null,
  propertyStatusCreatedById?: ModelIDInput | null,
};

export type PropertyStatus = {
  __typename: "PropertyStatus",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  propertyStatusCreatedById?: string | null,
};

export type UpdatePropertyStatusInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyStatusCreatedById?: string | null,
};

export type DeletePropertyStatusInput = {
  id: string,
};

export type CreatePropertyForInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  propertyForCreatedById?: string | null,
};

export type ModelPropertyForConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyForConditionInput | null > | null,
  or?: Array< ModelPropertyForConditionInput | null > | null,
  not?: ModelPropertyForConditionInput | null,
  propertyForCreatedById?: ModelIDInput | null,
};

export type PropertyFor = {
  __typename: "PropertyFor",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  propertyForCreatedById?: string | null,
};

export type UpdatePropertyForInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyForCreatedById?: string | null,
};

export type DeletePropertyForInput = {
  id: string,
};

export type CreatePropertyFeatureInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  propertyFeatureCreatedById?: string | null,
};

export type ModelPropertyFeatureConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyFeatureConditionInput | null > | null,
  or?: Array< ModelPropertyFeatureConditionInput | null > | null,
  not?: ModelPropertyFeatureConditionInput | null,
  propertyFeatureCreatedById?: ModelIDInput | null,
};

export type PropertyFeature = {
  __typename: "PropertyFeature",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  propertyFeatureCreatedById?: string | null,
};

export type UpdatePropertyFeatureInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  propertyFeatureCreatedById?: string | null,
};

export type DeletePropertyFeatureInput = {
  id: string,
};

export type CreateProjectInput = {
  id?: string | null,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  tagline?: string | null,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  slug: string,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  resAddress?: string | null,
  city?: string | null,
  areaSlug?: string | null,
  exteriorsGallery?: Array< string | null > | null,
  exteriorsDescription?: string | null,
  arExteriorsDescription?: string | null,
  frExteriorsDescription?: string | null,
  rsExteriorsDescription?: string | null,
  interiorsGallery?: Array< string | null > | null,
  interiorsDescription?: string | null,
  arInteriorsDescription?: string | null,
  frInteriorsDescription?: string | null,
  rsInteriorsDescription?: string | null,
  video?: string | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  projectImageId?: string | null,
  projectLogoId?: string | null,
  projectCreatedById?: string | null,
};

export type ModelProjectConditionInput = {
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  arTagline?: ModelStringInput | null,
  frTagline?: ModelStringInput | null,
  rsTagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  resAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  areaSlug?: ModelStringInput | null,
  exteriorsGallery?: ModelIDInput | null,
  exteriorsDescription?: ModelStringInput | null,
  arExteriorsDescription?: ModelStringInput | null,
  frExteriorsDescription?: ModelStringInput | null,
  rsExteriorsDescription?: ModelStringInput | null,
  interiorsGallery?: ModelIDInput | null,
  interiorsDescription?: ModelStringInput | null,
  arInteriorsDescription?: ModelStringInput | null,
  frInteriorsDescription?: ModelStringInput | null,
  rsInteriorsDescription?: ModelStringInput | null,
  video?: ModelStringInput | null,
  yearBuilt?: ModelIntInput | null,
  zipCode?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  projectImageId?: ModelIDInput | null,
  projectLogoId?: ModelIDInput | null,
  projectCreatedById?: ModelIDInput | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  title: string,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  tagline?: string | null,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  slug: string,
  address: string,
  arAddress?: string | null,
  frAddress?: string | null,
  resAddress?: string | null,
  image?: Attachment | null,
  logo?: Attachment | null,
  city?: string | null,
  areaSlug?: string | null,
  exteriors?: ModelAttachmentConnection | null,
  exteriorsGallery?: Array< string | null > | null,
  exteriorsDescription?: string | null,
  arExteriorsDescription?: string | null,
  frExteriorsDescription?: string | null,
  rsExteriorsDescription?: string | null,
  interiors?: ModelAttachmentConnection | null,
  interiorsGallery?: Array< string | null > | null,
  interiorsDescription?: string | null,
  arInteriorsDescription?: string | null,
  frInteriorsDescription?: string | null,
  rsInteriorsDescription?: string | null,
  video?: string | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  projectImageId?: string | null,
  projectLogoId?: string | null,
  projectCreatedById?: string | null,
};

export type UpdateProjectInput = {
  id: string,
  title?: string | null,
  arTitle?: string | null,
  frTitle?: string | null,
  rsTitle?: string | null,
  tagline?: string | null,
  arTagline?: string | null,
  frTagline?: string | null,
  rsTagline?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  slug?: string | null,
  address?: string | null,
  arAddress?: string | null,
  frAddress?: string | null,
  resAddress?: string | null,
  city?: string | null,
  areaSlug?: string | null,
  exteriorsGallery?: Array< string | null > | null,
  exteriorsDescription?: string | null,
  arExteriorsDescription?: string | null,
  frExteriorsDescription?: string | null,
  rsExteriorsDescription?: string | null,
  interiorsGallery?: Array< string | null > | null,
  interiorsDescription?: string | null,
  arInteriorsDescription?: string | null,
  frInteriorsDescription?: string | null,
  rsInteriorsDescription?: string | null,
  video?: string | null,
  yearBuilt?: number | null,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  projectImageId?: string | null,
  projectLogoId?: string | null,
  projectCreatedById?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateUnitTypeInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  parentId?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  unitTypeImageId?: string | null,
  unitTypeVrImageId?: string | null,
  unitTypeCreatedById?: string | null,
};

export type ModelUnitTypeConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUnitTypeConditionInput | null > | null,
  or?: Array< ModelUnitTypeConditionInput | null > | null,
  not?: ModelUnitTypeConditionInput | null,
  unitTypeImageId?: ModelIDInput | null,
  unitTypeVrImageId?: ModelIDInput | null,
  unitTypeCreatedById?: ModelIDInput | null,
};

export type UnitType = {
  __typename: "UnitType",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  parentId?: string | null,
  image?: Attachment | null,
  vrImage?: Attachment | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  unitTypeImageId?: string | null,
  unitTypeVrImageId?: string | null,
  unitTypeCreatedById?: string | null,
};

export type UpdateUnitTypeInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  parentId?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  unitTypeImageId?: string | null,
  unitTypeVrImageId?: string | null,
  unitTypeCreatedById?: string | null,
};

export type DeleteUnitTypeInput = {
  id: string,
};

export type CreateMaterialInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  materialImageId?: string | null,
  materialCreatedById?: string | null,
};

export type ModelMaterialConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMaterialConditionInput | null > | null,
  or?: Array< ModelMaterialConditionInput | null > | null,
  not?: ModelMaterialConditionInput | null,
  materialImageId?: ModelIDInput | null,
  materialCreatedById?: ModelIDInput | null,
};

export type Material = {
  __typename: "Material",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  image?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  materialImageId?: string | null,
  materialCreatedById?: string | null,
};

export type UpdateMaterialInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  materialImageId?: string | null,
  materialCreatedById?: string | null,
};

export type DeleteMaterialInput = {
  id: string,
};

export type CreateAmenityInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  amenityImageId?: string | null,
  amenityCreatedById?: string | null,
};

export type ModelAmenityConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAmenityConditionInput | null > | null,
  or?: Array< ModelAmenityConditionInput | null > | null,
  not?: ModelAmenityConditionInput | null,
  amenityImageId?: ModelIDInput | null,
  amenityCreatedById?: ModelIDInput | null,
};

export type Amenity = {
  __typename: "Amenity",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  image?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  amenityImageId?: string | null,
  amenityCreatedById?: string | null,
};

export type UpdateAmenityInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  amenityImageId?: string | null,
  amenityCreatedById?: string | null,
};

export type DeleteAmenityInput = {
  id: string,
};

export type CreateCityInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  country: string,
  state: string,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  cityImageId?: string | null,
  cityCreatedById?: string | null,
};

export type ModelCityConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCityConditionInput | null > | null,
  or?: Array< ModelCityConditionInput | null > | null,
  not?: ModelCityConditionInput | null,
  cityImageId?: ModelIDInput | null,
  cityCreatedById?: ModelIDInput | null,
};

export type City = {
  __typename: "City",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  image?: Attachment | null,
  country: string,
  state: string,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  cityImageId?: string | null,
  cityCreatedById?: string | null,
};

export type UpdateCityInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  country?: string | null,
  state?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  cityImageId?: string | null,
  cityCreatedById?: string | null,
};

export type DeleteCityInput = {
  id: string,
};

export type CreateAreaInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  citySlug: string,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  areaCreatedById?: string | null,
};

export type ModelAreaConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  citySlug?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAreaConditionInput | null > | null,
  or?: Array< ModelAreaConditionInput | null > | null,
  not?: ModelAreaConditionInput | null,
  areaCreatedById?: ModelIDInput | null,
};

export type Area = {
  __typename: "Area",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  citySlug: string,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  areaCreatedById?: string | null,
};

export type UpdateAreaInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  citySlug?: string | null,
  zipCode?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  areaCreatedById?: string | null,
};

export type DeleteAreaInput = {
  id: string,
};

export type CreateServiceInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  description: string,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  serviceImageId?: string | null,
  serviceCreatedById?: string | null,
};

export type ModelServiceConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelServiceConditionInput | null > | null,
  or?: Array< ModelServiceConditionInput | null > | null,
  not?: ModelServiceConditionInput | null,
  serviceImageId?: ModelIDInput | null,
  serviceCreatedById?: ModelIDInput | null,
};

export type Service = {
  __typename: "Service",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  image?: Attachment | null,
  description: string,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  serviceImageId?: string | null,
  serviceCreatedById?: string | null,
};

export type UpdateServiceInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  description?: string | null,
  arDescription?: string | null,
  frDescription?: string | null,
  rsDescription?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  serviceImageId?: string | null,
  serviceCreatedById?: string | null,
};

export type DeleteServiceInput = {
  id: string,
};

export type CreateSubscriberInput = {
  id?: string | null,
  email: string,
  deleted?: string | null,
  createdAt?: string | null,
};

export type ModelSubscriberConditionInput = {
  email?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSubscriberConditionInput | null > | null,
  or?: Array< ModelSubscriberConditionInput | null > | null,
  not?: ModelSubscriberConditionInput | null,
};

export type Subscriber = {
  __typename: "Subscriber",
  id: string,
  email: string,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSubscriberInput = {
  id: string,
  email?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
};

export type DeleteSubscriberInput = {
  id: string,
};

export type CreateDeveloperInput = {
  id?: string | null,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  developerImageId?: string | null,
  developerCreatedById?: string | null,
};

export type ModelDeveloperConditionInput = {
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDeveloperConditionInput | null > | null,
  or?: Array< ModelDeveloperConditionInput | null > | null,
  not?: ModelDeveloperConditionInput | null,
  developerImageId?: ModelIDInput | null,
  developerCreatedById?: ModelIDInput | null,
};

export type Developer = {
  __typename: "Developer",
  id: string,
  name: string,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug: string,
  image?: Attachment | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  developerImageId?: string | null,
  developerCreatedById?: string | null,
};

export type UpdateDeveloperInput = {
  id: string,
  name?: string | null,
  arName?: string | null,
  frName?: string | null,
  rsName?: string | null,
  slug?: string | null,
  propertiesCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  developerImageId?: string | null,
  developerCreatedById?: string | null,
};

export type DeleteDeveloperInput = {
  id: string,
};

export type CreateCourseStudentInput = {
  id?: string | null,
  userID: string,
  courseID: string,
};

export type ModelCourseStudentConditionInput = {
  userID?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  and?: Array< ModelCourseStudentConditionInput | null > | null,
  or?: Array< ModelCourseStudentConditionInput | null > | null,
  not?: ModelCourseStudentConditionInput | null,
};

export type UpdateCourseStudentInput = {
  id: string,
  userID?: string | null,
  courseID?: string | null,
};

export type DeleteCourseStudentInput = {
  id: string,
};

export type CreateAccountLanguageInput = {
  id?: string | null,
  accountID: string,
  languageID: string,
};

export type ModelAccountLanguageConditionInput = {
  accountID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelAccountLanguageConditionInput | null > | null,
  or?: Array< ModelAccountLanguageConditionInput | null > | null,
  not?: ModelAccountLanguageConditionInput | null,
};

export type UpdateAccountLanguageInput = {
  id: string,
  accountID?: string | null,
  languageID?: string | null,
};

export type DeleteAccountLanguageInput = {
  id: string,
};

export type CreatePostCategoryInput = {
  id?: string | null,
  categoryID: string,
  postID: string,
};

export type ModelPostCategoryConditionInput = {
  categoryID?: ModelIDInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostCategoryConditionInput | null > | null,
  or?: Array< ModelPostCategoryConditionInput | null > | null,
  not?: ModelPostCategoryConditionInput | null,
};

export type UpdatePostCategoryInput = {
  id: string,
  categoryID?: string | null,
  postID?: string | null,
};

export type DeletePostCategoryInput = {
  id: string,
};

export type CreateQuizQuestionInput = {
  id?: string | null,
  quizID: string,
  questionID: string,
};

export type ModelQuizQuestionConditionInput = {
  quizID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuizQuestionConditionInput | null > | null,
  or?: Array< ModelQuizQuestionConditionInput | null > | null,
  not?: ModelQuizQuestionConditionInput | null,
};

export type UpdateQuizQuestionInput = {
  id: string,
  quizID?: string | null,
  questionID?: string | null,
};

export type DeleteQuizQuestionInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  group?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelEmailVerificationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationFilterInput | null > | null,
  or?: Array< ModelEmailVerificationFilterInput | null > | null,
  not?: ModelEmailVerificationFilterInput | null,
};

export type ModelEmailVerificationConnection = {
  __typename: "ModelEmailVerificationConnection",
  items:  Array<EmailVerification | null >,
  nextToken?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  arSiteTitle?: ModelStringInput | null,
  frSiteTitle?: ModelStringInput | null,
  rsSiteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  arTagline?: ModelStringInput | null,
  frTagline?: ModelStringInput | null,
  rsTagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  rsAddress?: ModelStringInput | null,
  workingHours?: ModelStringInput | null,
  arWorkingHours?: ModelStringInput | null,
  frWorkingHours?: ModelStringInput | null,
  rsWorkingHours?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  accountLogoId?: ModelIDInput | null,
  accountCreatedById?: ModelIDInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelSocialLinkFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSocialLinkFilterInput | null > | null,
  or?: Array< ModelSocialLinkFilterInput | null > | null,
  not?: ModelSocialLinkFilterInput | null,
  accountSocialLinksId?: ModelIDInput | null,
  socialLinkCreatedById?: ModelIDInput | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  isDashboard?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
  featureCreatedById?: ModelIDInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
};

export type ModelLanguageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLanguageFilterInput | null > | null,
  or?: Array< ModelLanguageFilterInput | null > | null,
  not?: ModelLanguageFilterInput | null,
  languageCreatedById?: ModelIDInput | null,
};

export type ModelLanguageConnection = {
  __typename: "ModelLanguageConnection",
  items:  Array<Language | null >,
  nextToken?: string | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  fileurl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  filesize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
  mediaCreatedById?: ModelIDInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  fileurl?: ModelStringInput | null,
  alternativeText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
  mediaAttachmentsId?: ModelIDInput | null,
  propertyGalleryId?: ModelIDInput | null,
  projectExteriorsId?: ModelIDInput | null,
  projectInteriorsId?: ModelIDInput | null,
  attachmentCreatedById?: ModelIDInput | null,
};

export type ModelMenuFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  locations?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuFilterInput | null > | null,
  or?: Array< ModelMenuFilterInput | null > | null,
  not?: ModelMenuFilterInput | null,
  menuCreatedById?: ModelIDInput | null,
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection",
  items:  Array<Menu | null >,
  nextToken?: string | null,
};

export type ModelMenuItemFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuItemFilterInput | null > | null,
  or?: Array< ModelMenuItemFilterInput | null > | null,
  not?: ModelMenuItemFilterInput | null,
  menuItemsId?: ModelIDInput | null,
  menuItemPageId?: ModelIDInput | null,
  menuItemCreatedById?: ModelIDInput | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  history?: ModelStringInput | null,
  arHistory?: ModelStringInput | null,
  frHistory?: ModelStringInput | null,
  rsHistory?: ModelStringInput | null,
  values?: ModelStringInput | null,
  arValues?: ModelStringInput | null,
  frValues?: ModelStringInput | null,
  rsValues?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
  pageThumbnailId?: ModelIDInput | null,
  pageCreatedById?: ModelIDInput | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  categoryThumbnailId?: ModelIDInput | null,
  categoryCreatedById?: ModelIDInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
  postThumbnailId?: ModelIDInput | null,
  postCreatedById?: ModelIDInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type ModelTestimonialFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  arContent?: ModelStringInput | null,
  frContent?: ModelStringInput | null,
  rsContent?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTestimonialFilterInput | null > | null,
  or?: Array< ModelTestimonialFilterInput | null > | null,
  not?: ModelTestimonialFilterInput | null,
  testimonialThumbnailId?: ModelIDInput | null,
  testimonialCreatedById?: ModelIDInput | null,
};

export type ModelTestimonialConnection = {
  __typename: "ModelTestimonialConnection",
  items:  Array<Testimonial | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelSlideFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSlideFilterInput | null > | null,
  or?: Array< ModelSlideFilterInput | null > | null,
  not?: ModelSlideFilterInput | null,
  slideImageId?: ModelIDInput | null,
  slideMobImageId?: ModelIDInput | null,
  slideCreatedById?: ModelIDInput | null,
};

export type ModelSlideConnection = {
  __typename: "ModelSlideConnection",
  items:  Array<Slide | null >,
  nextToken?: string | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisiteQuiz?: ModelStringInput | null,
  prerequisiteQuizTitle?: ModelStringInput | null,
  prerequisiteQuizSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  prerequisiteModuleId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
  courseImageId?: ModelIDInput | null,
  courseCreatedById?: ModelIDInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelModuleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  afterModuleQuiz?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextModule?: ModelStringInput | null,
  nextModuleTitle?: ModelStringInput | null,
  nextModuleId?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleFilterInput | null > | null,
  or?: Array< ModelModuleFilterInput | null > | null,
  not?: ModelModuleFilterInput | null,
  courseModulesId?: ModelIDInput | null,
  moduleImageId?: ModelIDInput | null,
  moduleVrContentId?: ModelIDInput | null,
  moduleCreatedById?: ModelIDInput | null,
};

export type ModelModuleStatusFilterInput = {
  id?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleStatusFilterInput | null > | null,
  or?: Array< ModelModuleStatusFilterInput | null > | null,
  not?: ModelModuleStatusFilterInput | null,
  moduleStatusModuleId?: ModelIDInput | null,
  moduleStatusStudentId?: ModelIDInput | null,
};

export type ModelModuleStatusConnection = {
  __typename: "ModelModuleStatusConnection",
  items:  Array<ModuleStatus | null >,
  nextToken?: string | null,
};

export type ModelQuizFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  prerequisiteSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizFilterInput | null > | null,
  or?: Array< ModelQuizFilterInput | null > | null,
  not?: ModelQuizFilterInput | null,
  moduleQuizzesId?: ModelIDInput | null,
  quizTypeId?: ModelIDInput | null,
  quizCreatedById?: ModelIDInput | null,
};

export type ModelQuizTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizTypeFilterInput | null > | null,
  or?: Array< ModelQuizTypeFilterInput | null > | null,
  not?: ModelQuizTypeFilterInput | null,
  quizTypeCreatedById?: ModelIDInput | null,
};

export type ModelQuizTypeConnection = {
  __typename: "ModelQuizTypeConnection",
  items:  Array<QuizType | null >,
  nextToken?: string | null,
};

export type ModelQuizStatusFilterInput = {
  id?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  totalScore?: ModelIntInput | null,
  studentScore?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizStatusFilterInput | null > | null,
  or?: Array< ModelQuizStatusFilterInput | null > | null,
  not?: ModelQuizStatusFilterInput | null,
  quizStatusQuizId?: ModelIDInput | null,
  quizStatusStudentId?: ModelIDInput | null,
};

export type ModelQuizStatusConnection = {
  __typename: "ModelQuizStatusConnection",
  items:  Array<QuizStatus | null >,
  nextToken?: string | null,
};

export type ModelQuestionTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionTypeFilterInput | null > | null,
  or?: Array< ModelQuestionTypeFilterInput | null > | null,
  not?: ModelQuestionTypeFilterInput | null,
  questionTypeCreatedById?: ModelIDInput | null,
};

export type ModelQuestionTypeConnection = {
  __typename: "ModelQuestionTypeConnection",
  items:  Array<QuestionType | null >,
  nextToken?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  arAnswer?: ModelStringInput | null,
  frAnswer?: ModelStringInput | null,
  rsAnswer?: ModelStringInput | null,
  options?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  questionTypeId?: ModelIDInput | null,
  questionImageId?: ModelIDInput | null,
  questionCreatedById?: ModelIDInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null,
  values?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  isCorrect?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnswerFilterInput | null > | null,
  or?: Array< ModelAnswerFilterInput | null > | null,
  not?: ModelAnswerFilterInput | null,
  answerQuestionId?: ModelIDInput | null,
  answerStudentId?: ModelIDInput | null,
};

export type ModelAnswerConnection = {
  __typename: "ModelAnswerConnection",
  items:  Array<Answer | null >,
  nextToken?: string | null,
};

export type ModelPropertyFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  rsAddress?: ModelStringInput | null,
  projectSlug?: ModelStringInput | null,
  areaSlug?: ModelStringInput | null,
  baths?: ModelIntInput | null,
  beds?: ModelIntInput | null,
  area?: ModelIntInput | null,
  propertyFor?: ModelStringInput | null,
  type?: ModelStringInput | null,
  city?: ModelStringInput | null,
  featured?: ModelBooleanInput | null,
  garage?: ModelIntInput | null,
  yearBuilt?: ModelIntInput | null,
  zipCode?: ModelStringInput | null,
  price?: ModelIntInput | null,
  garageSize?: ModelIntInput | null,
  propertyID?: ModelStringInput | null,
  video?: ModelStringInput | null,
  features?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyFilterInput | null > | null,
  or?: Array< ModelPropertyFilterInput | null > | null,
  not?: ModelPropertyFilterInput | null,
  propertyImageId?: ModelIDInput | null,
  propertyVirtualImageId?: ModelIDInput | null,
  propertyCreatedById?: ModelIDInput | null,
};

export type ModelPropertyConnection = {
  __typename: "ModelPropertyConnection",
  items:  Array<Property | null >,
  nextToken?: string | null,
};

export type ModelPropertyByProjectCompositeKeyConditionInput = {
  eq?: ModelPropertyByProjectCompositeKeyInput | null,
  le?: ModelPropertyByProjectCompositeKeyInput | null,
  lt?: ModelPropertyByProjectCompositeKeyInput | null,
  ge?: ModelPropertyByProjectCompositeKeyInput | null,
  gt?: ModelPropertyByProjectCompositeKeyInput | null,
  between?: Array< ModelPropertyByProjectCompositeKeyInput | null > | null,
  beginsWith?: ModelPropertyByProjectCompositeKeyInput | null,
};

export type ModelPropertyByProjectCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelPropertyByAreaCompositeKeyConditionInput = {
  eq?: ModelPropertyByAreaCompositeKeyInput | null,
  le?: ModelPropertyByAreaCompositeKeyInput | null,
  lt?: ModelPropertyByAreaCompositeKeyInput | null,
  ge?: ModelPropertyByAreaCompositeKeyInput | null,
  gt?: ModelPropertyByAreaCompositeKeyInput | null,
  between?: Array< ModelPropertyByAreaCompositeKeyInput | null > | null,
  beginsWith?: ModelPropertyByAreaCompositeKeyInput | null,
};

export type ModelPropertyByAreaCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelPropertyTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyTypeFilterInput | null > | null,
  or?: Array< ModelPropertyTypeFilterInput | null > | null,
  not?: ModelPropertyTypeFilterInput | null,
  propertyTypeImageId?: ModelIDInput | null,
  propertyTypeCreatedById?: ModelIDInput | null,
};

export type ModelPropertyTypeConnection = {
  __typename: "ModelPropertyTypeConnection",
  items:  Array<PropertyType | null >,
  nextToken?: string | null,
};

export type ModelPropertyStatusFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyStatusFilterInput | null > | null,
  or?: Array< ModelPropertyStatusFilterInput | null > | null,
  not?: ModelPropertyStatusFilterInput | null,
  propertyStatusCreatedById?: ModelIDInput | null,
};

export type ModelPropertyStatusConnection = {
  __typename: "ModelPropertyStatusConnection",
  items:  Array<PropertyStatus | null >,
  nextToken?: string | null,
};

export type ModelPropertyForFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyForFilterInput | null > | null,
  or?: Array< ModelPropertyForFilterInput | null > | null,
  not?: ModelPropertyForFilterInput | null,
  propertyForCreatedById?: ModelIDInput | null,
};

export type ModelPropertyForConnection = {
  __typename: "ModelPropertyForConnection",
  items:  Array<PropertyFor | null >,
  nextToken?: string | null,
};

export type ModelPropertyFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPropertyFeatureFilterInput | null > | null,
  or?: Array< ModelPropertyFeatureFilterInput | null > | null,
  not?: ModelPropertyFeatureFilterInput | null,
  propertyFeatureCreatedById?: ModelIDInput | null,
};

export type ModelPropertyFeatureConnection = {
  __typename: "ModelPropertyFeatureConnection",
  items:  Array<PropertyFeature | null >,
  nextToken?: string | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  arTitle?: ModelStringInput | null,
  frTitle?: ModelStringInput | null,
  rsTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  arTagline?: ModelStringInput | null,
  frTagline?: ModelStringInput | null,
  rsTagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  address?: ModelStringInput | null,
  arAddress?: ModelStringInput | null,
  frAddress?: ModelStringInput | null,
  resAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  areaSlug?: ModelStringInput | null,
  exteriorsGallery?: ModelIDInput | null,
  exteriorsDescription?: ModelStringInput | null,
  arExteriorsDescription?: ModelStringInput | null,
  frExteriorsDescription?: ModelStringInput | null,
  rsExteriorsDescription?: ModelStringInput | null,
  interiorsGallery?: ModelIDInput | null,
  interiorsDescription?: ModelStringInput | null,
  arInteriorsDescription?: ModelStringInput | null,
  frInteriorsDescription?: ModelStringInput | null,
  rsInteriorsDescription?: ModelStringInput | null,
  video?: ModelStringInput | null,
  yearBuilt?: ModelIntInput | null,
  zipCode?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
  projectImageId?: ModelIDInput | null,
  projectLogoId?: ModelIDInput | null,
  projectCreatedById?: ModelIDInput | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type ModelProjectByTitleCompositeKeyConditionInput = {
  eq?: ModelProjectByTitleCompositeKeyInput | null,
  le?: ModelProjectByTitleCompositeKeyInput | null,
  lt?: ModelProjectByTitleCompositeKeyInput | null,
  ge?: ModelProjectByTitleCompositeKeyInput | null,
  gt?: ModelProjectByTitleCompositeKeyInput | null,
  between?: Array< ModelProjectByTitleCompositeKeyInput | null > | null,
  beginsWith?: ModelProjectByTitleCompositeKeyInput | null,
};

export type ModelProjectByTitleCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelProjectBySlugCompositeKeyConditionInput = {
  eq?: ModelProjectBySlugCompositeKeyInput | null,
  le?: ModelProjectBySlugCompositeKeyInput | null,
  lt?: ModelProjectBySlugCompositeKeyInput | null,
  ge?: ModelProjectBySlugCompositeKeyInput | null,
  gt?: ModelProjectBySlugCompositeKeyInput | null,
  between?: Array< ModelProjectBySlugCompositeKeyInput | null > | null,
  beginsWith?: ModelProjectBySlugCompositeKeyInput | null,
};

export type ModelProjectBySlugCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelProjectByCityCompositeKeyConditionInput = {
  eq?: ModelProjectByCityCompositeKeyInput | null,
  le?: ModelProjectByCityCompositeKeyInput | null,
  lt?: ModelProjectByCityCompositeKeyInput | null,
  ge?: ModelProjectByCityCompositeKeyInput | null,
  gt?: ModelProjectByCityCompositeKeyInput | null,
  between?: Array< ModelProjectByCityCompositeKeyInput | null > | null,
  beginsWith?: ModelProjectByCityCompositeKeyInput | null,
};

export type ModelProjectByCityCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelProjectByAreaCompositeKeyConditionInput = {
  eq?: ModelProjectByAreaCompositeKeyInput | null,
  le?: ModelProjectByAreaCompositeKeyInput | null,
  lt?: ModelProjectByAreaCompositeKeyInput | null,
  ge?: ModelProjectByAreaCompositeKeyInput | null,
  gt?: ModelProjectByAreaCompositeKeyInput | null,
  between?: Array< ModelProjectByAreaCompositeKeyInput | null > | null,
  beginsWith?: ModelProjectByAreaCompositeKeyInput | null,
};

export type ModelProjectByAreaCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelUnitTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUnitTypeFilterInput | null > | null,
  or?: Array< ModelUnitTypeFilterInput | null > | null,
  not?: ModelUnitTypeFilterInput | null,
  unitTypeImageId?: ModelIDInput | null,
  unitTypeVrImageId?: ModelIDInput | null,
  unitTypeCreatedById?: ModelIDInput | null,
};

export type ModelUnitTypeConnection = {
  __typename: "ModelUnitTypeConnection",
  items:  Array<UnitType | null >,
  nextToken?: string | null,
};

export type ModelMaterialFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMaterialFilterInput | null > | null,
  or?: Array< ModelMaterialFilterInput | null > | null,
  not?: ModelMaterialFilterInput | null,
  materialImageId?: ModelIDInput | null,
  materialCreatedById?: ModelIDInput | null,
};

export type ModelMaterialConnection = {
  __typename: "ModelMaterialConnection",
  items:  Array<Material | null >,
  nextToken?: string | null,
};

export type ModelAmenityFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAmenityFilterInput | null > | null,
  or?: Array< ModelAmenityFilterInput | null > | null,
  not?: ModelAmenityFilterInput | null,
  amenityImageId?: ModelIDInput | null,
  amenityCreatedById?: ModelIDInput | null,
};

export type ModelAmenityConnection = {
  __typename: "ModelAmenityConnection",
  items:  Array<Amenity | null >,
  nextToken?: string | null,
};

export type ModelCityFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCityFilterInput | null > | null,
  or?: Array< ModelCityFilterInput | null > | null,
  not?: ModelCityFilterInput | null,
  cityImageId?: ModelIDInput | null,
  cityCreatedById?: ModelIDInput | null,
};

export type ModelCityConnection = {
  __typename: "ModelCityConnection",
  items:  Array<City | null >,
  nextToken?: string | null,
};

export type ModelAreaFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  citySlug?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAreaFilterInput | null > | null,
  or?: Array< ModelAreaFilterInput | null > | null,
  not?: ModelAreaFilterInput | null,
  areaCreatedById?: ModelIDInput | null,
};

export type ModelAreaConnection = {
  __typename: "ModelAreaConnection",
  items:  Array<Area | null >,
  nextToken?: string | null,
};

export type ModelAreaByCityCompositeKeyConditionInput = {
  eq?: ModelAreaByCityCompositeKeyInput | null,
  le?: ModelAreaByCityCompositeKeyInput | null,
  lt?: ModelAreaByCityCompositeKeyInput | null,
  ge?: ModelAreaByCityCompositeKeyInput | null,
  gt?: ModelAreaByCityCompositeKeyInput | null,
  between?: Array< ModelAreaByCityCompositeKeyInput | null > | null,
  beginsWith?: ModelAreaByCityCompositeKeyInput | null,
};

export type ModelAreaByCityCompositeKeyInput = {
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelServiceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  arDescription?: ModelStringInput | null,
  frDescription?: ModelStringInput | null,
  rsDescription?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelServiceFilterInput | null > | null,
  or?: Array< ModelServiceFilterInput | null > | null,
  not?: ModelServiceFilterInput | null,
  serviceImageId?: ModelIDInput | null,
  serviceCreatedById?: ModelIDInput | null,
};

export type ModelServiceConnection = {
  __typename: "ModelServiceConnection",
  items:  Array<Service | null >,
  nextToken?: string | null,
};

export type ModelSubscriberFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSubscriberFilterInput | null > | null,
  or?: Array< ModelSubscriberFilterInput | null > | null,
  not?: ModelSubscriberFilterInput | null,
};

export type ModelSubscriberConnection = {
  __typename: "ModelSubscriberConnection",
  items:  Array<Subscriber | null >,
  nextToken?: string | null,
};

export type ModelDeveloperFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  arName?: ModelStringInput | null,
  frName?: ModelStringInput | null,
  rsName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  propertiesCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDeveloperFilterInput | null > | null,
  or?: Array< ModelDeveloperFilterInput | null > | null,
  not?: ModelDeveloperFilterInput | null,
  developerImageId?: ModelIDInput | null,
  developerCreatedById?: ModelIDInput | null,
};

export type ModelDeveloperConnection = {
  __typename: "ModelDeveloperConnection",
  items:  Array<Developer | null >,
  nextToken?: string | null,
};

export type ModelCourseStudentFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  and?: Array< ModelCourseStudentFilterInput | null > | null,
  or?: Array< ModelCourseStudentFilterInput | null > | null,
  not?: ModelCourseStudentFilterInput | null,
};

export type ModelAccountLanguageFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelAccountLanguageFilterInput | null > | null,
  or?: Array< ModelAccountLanguageFilterInput | null > | null,
  not?: ModelAccountLanguageFilterInput | null,
};

export type ModelPostCategoryFilterInput = {
  id?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostCategoryFilterInput | null > | null,
  or?: Array< ModelPostCategoryFilterInput | null > | null,
  not?: ModelPostCategoryFilterInput | null,
};

export type ModelQuizQuestionFilterInput = {
  id?: ModelIDInput | null,
  quizID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuizQuestionFilterInput | null > | null,
  or?: Array< ModelQuizQuestionFilterInput | null > | null,
  not?: ModelQuizQuestionFilterInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  preferred_username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  email_verified?: ModelSubscriptionBooleanInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  phone_number_verified?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  given_name?: ModelSubscriptionStringInput | null,
  middle_name?: ModelSubscriptionStringInput | null,
  family_name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  birthdate?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  locale?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  zoneinfo?: ModelSubscriptionStringInput | null,
  verification_code?: ModelSubscriptionStringInput | null,
  group?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionEmailVerificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  verification_code?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmailVerificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmailVerificationFilterInput | null > | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  domain?: ModelSubscriptionStringInput | null,
  siteTitle?: ModelSubscriptionStringInput | null,
  arSiteTitle?: ModelSubscriptionStringInput | null,
  frSiteTitle?: ModelSubscriptionStringInput | null,
  rsSiteTitle?: ModelSubscriptionStringInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  arTagline?: ModelSubscriptionStringInput | null,
  frTagline?: ModelSubscriptionStringInput | null,
  rsTagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  arDescription?: ModelSubscriptionStringInput | null,
  frDescription?: ModelSubscriptionStringInput | null,
  rsDescription?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  arAddress?: ModelSubscriptionStringInput | null,
  frAddress?: ModelSubscriptionStringInput | null,
  rsAddress?: ModelSubscriptionStringInput | null,
  workingHours?: ModelSubscriptionStringInput | null,
  arWorkingHours?: ModelSubscriptionStringInput | null,
  frWorkingHours?: ModelSubscriptionStringInput | null,
  rsWorkingHours?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  siteAddress?: ModelSubscriptionStringInput | null,
  defaultLanguage?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionSocialLinkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSocialLinkFilterInput | null > | null,
  or?: Array< ModelSubscriptionSocialLinkFilterInput | null > | null,
};

export type ModelSubscriptionFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  isDashboard?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
};

export type ModelSubscriptionLanguageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
  or?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
};

export type ModelSubscriptionMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fileurl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  filesize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fileurl?: ModelSubscriptionStringInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
};

export type ModelSubscriptionMenuFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  locations?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMenuFilterInput | null > | null,
  or?: Array< ModelSubscriptionMenuFilterInput | null > | null,
};

export type ModelSubscriptionMenuItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
};

export type ModelSubscriptionPageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  arContent?: ModelSubscriptionStringInput | null,
  frContent?: ModelSubscriptionStringInput | null,
  rsContent?: ModelSubscriptionStringInput | null,
  history?: ModelSubscriptionStringInput | null,
  arHistory?: ModelSubscriptionStringInput | null,
  frHistory?: ModelSubscriptionStringInput | null,
  rsHistory?: ModelSubscriptionStringInput | null,
  values?: ModelSubscriptionStringInput | null,
  arValues?: ModelSubscriptionStringInput | null,
  frValues?: ModelSubscriptionStringInput | null,
  rsValues?: ModelSubscriptionStringInput | null,
  excerpt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPageFilterInput | null > | null,
  or?: Array< ModelSubscriptionPageFilterInput | null > | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  arDescription?: ModelSubscriptionStringInput | null,
  frDescription?: ModelSubscriptionStringInput | null,
  rsDescription?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
};

export type ModelSubscriptionPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  arContent?: ModelSubscriptionStringInput | null,
  frContent?: ModelSubscriptionStringInput | null,
  rsContent?: ModelSubscriptionStringInput | null,
  excerpt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostFilterInput | null > | null,
};

export type ModelSubscriptionTestimonialFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  arContent?: ModelSubscriptionStringInput | null,
  frContent?: ModelSubscriptionStringInput | null,
  rsContent?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestimonialFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestimonialFilterInput | null > | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  subject?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
};

export type ModelSubscriptionSlideFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSlideFilterInput | null > | null,
  or?: Array< ModelSubscriptionSlideFilterInput | null > | null,
};

export type ModelSubscriptionCourseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  prerequisiteQuiz?: ModelSubscriptionStringInput | null,
  prerequisiteQuizTitle?: ModelSubscriptionStringInput | null,
  prerequisiteQuizSlug?: ModelSubscriptionStringInput | null,
  prerequisiteModule?: ModelSubscriptionStringInput | null,
  prerequisiteModuleTitle?: ModelSubscriptionStringInput | null,
  prerequisiteModuleId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCourseFilterInput | null > | null,
  or?: Array< ModelSubscriptionCourseFilterInput | null > | null,
};

export type ModelSubscriptionModuleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  prerequisite?: ModelSubscriptionStringInput | null,
  prerequisiteTitle?: ModelSubscriptionStringInput | null,
  afterModuleQuiz?: ModelSubscriptionStringInput | null,
  nextQuiz?: ModelSubscriptionStringInput | null,
  nextQuizTitle?: ModelSubscriptionStringInput | null,
  nextQuizSlug?: ModelSubscriptionStringInput | null,
  nextModule?: ModelSubscriptionStringInput | null,
  nextModuleTitle?: ModelSubscriptionStringInput | null,
  nextModuleId?: ModelSubscriptionStringInput | null,
  nextCourse?: ModelSubscriptionStringInput | null,
  nextCourseTitle?: ModelSubscriptionStringInput | null,
  nextCourseSlug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModuleFilterInput | null > | null,
  or?: Array< ModelSubscriptionModuleFilterInput | null > | null,
};

export type ModelSubscriptionModuleStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModuleStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionModuleStatusFilterInput | null > | null,
};

export type ModelSubscriptionQuizFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  prerequisite?: ModelSubscriptionStringInput | null,
  prerequisiteTitle?: ModelSubscriptionStringInput | null,
  prerequisiteSlug?: ModelSubscriptionStringInput | null,
  prerequisiteModule?: ModelSubscriptionStringInput | null,
  prerequisiteModuleTitle?: ModelSubscriptionStringInput | null,
  nextQuiz?: ModelSubscriptionStringInput | null,
  nextQuizTitle?: ModelSubscriptionStringInput | null,
  nextQuizSlug?: ModelSubscriptionStringInput | null,
  nextCourse?: ModelSubscriptionStringInput | null,
  nextCourseTitle?: ModelSubscriptionStringInput | null,
  nextCourseSlug?: ModelSubscriptionStringInput | null,
  hasFeedback?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizFilterInput | null > | null,
};

export type ModelSubscriptionQuizTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizTypeFilterInput | null > | null,
};

export type ModelSubscriptionQuizStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  totalScore?: ModelSubscriptionIntInput | null,
  studentScore?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizStatusFilterInput | null > | null,
};

export type ModelSubscriptionQuestionTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionTypeFilterInput | null > | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  arAnswer?: ModelSubscriptionStringInput | null,
  frAnswer?: ModelSubscriptionStringInput | null,
  rsAnswer?: ModelSubscriptionStringInput | null,
  options?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
};

export type ModelSubscriptionAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  values?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  isCorrect?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
};

export type ModelSubscriptionPropertyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slug?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  arDescription?: ModelSubscriptionStringInput | null,
  frDescription?: ModelSubscriptionStringInput | null,
  rsDescription?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  arAddress?: ModelSubscriptionStringInput | null,
  frAddress?: ModelSubscriptionStringInput | null,
  rsAddress?: ModelSubscriptionStringInput | null,
  projectSlug?: ModelSubscriptionStringInput | null,
  areaSlug?: ModelSubscriptionStringInput | null,
  baths?: ModelSubscriptionIntInput | null,
  beds?: ModelSubscriptionIntInput | null,
  area?: ModelSubscriptionIntInput | null,
  propertyFor?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  featured?: ModelSubscriptionBooleanInput | null,
  garage?: ModelSubscriptionIntInput | null,
  yearBuilt?: ModelSubscriptionIntInput | null,
  zipCode?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  garageSize?: ModelSubscriptionIntInput | null,
  propertyID?: ModelSubscriptionStringInput | null,
  video?: ModelSubscriptionStringInput | null,
  features?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
};

export type ModelSubscriptionPropertyTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  propertiesCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyTypeFilterInput | null > | null,
};

export type ModelSubscriptionPropertyStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyStatusFilterInput | null > | null,
};

export type ModelSubscriptionPropertyForFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyForFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyForFilterInput | null > | null,
};

export type ModelSubscriptionPropertyFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFeatureFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  arTitle?: ModelSubscriptionStringInput | null,
  frTitle?: ModelSubscriptionStringInput | null,
  rsTitle?: ModelSubscriptionStringInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  arTagline?: ModelSubscriptionStringInput | null,
  frTagline?: ModelSubscriptionStringInput | null,
  rsTagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  arDescription?: ModelSubscriptionStringInput | null,
  frDescription?: ModelSubscriptionStringInput | null,
  rsDescription?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  arAddress?: ModelSubscriptionStringInput | null,
  frAddress?: ModelSubscriptionStringInput | null,
  resAddress?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  areaSlug?: ModelSubscriptionStringInput | null,
  exteriorsGallery?: ModelSubscriptionIDInput | null,
  exteriorsDescription?: ModelSubscriptionStringInput | null,
  arExteriorsDescription?: ModelSubscriptionStringInput | null,
  frExteriorsDescription?: ModelSubscriptionStringInput | null,
  rsExteriorsDescription?: ModelSubscriptionStringInput | null,
  interiorsGallery?: ModelSubscriptionIDInput | null,
  interiorsDescription?: ModelSubscriptionStringInput | null,
  arInteriorsDescription?: ModelSubscriptionStringInput | null,
  frInteriorsDescription?: ModelSubscriptionStringInput | null,
  rsInteriorsDescription?: ModelSubscriptionStringInput | null,
  video?: ModelSubscriptionStringInput | null,
  yearBuilt?: ModelSubscriptionIntInput | null,
  zipCode?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionUnitTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  propertiesCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUnitTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitTypeFilterInput | null > | null,
};

export type ModelSubscriptionMaterialFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMaterialFilterInput | null > | null,
  or?: Array< ModelSubscriptionMaterialFilterInput | null > | null,
};

export type ModelSubscriptionAmenityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAmenityFilterInput | null > | null,
  or?: Array< ModelSubscriptionAmenityFilterInput | null > | null,
};

export type ModelSubscriptionCityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  propertiesCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCityFilterInput | null > | null,
  or?: Array< ModelSubscriptionCityFilterInput | null > | null,
};

export type ModelSubscriptionAreaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  citySlug?: ModelSubscriptionStringInput | null,
  zipCode?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAreaFilterInput | null > | null,
  or?: Array< ModelSubscriptionAreaFilterInput | null > | null,
};

export type ModelSubscriptionServiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  arDescription?: ModelSubscriptionStringInput | null,
  frDescription?: ModelSubscriptionStringInput | null,
  rsDescription?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceFilterInput | null > | null,
};

export type ModelSubscriptionSubscriberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubscriberFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubscriberFilterInput | null > | null,
};

export type ModelSubscriptionDeveloperFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  arName?: ModelSubscriptionStringInput | null,
  frName?: ModelSubscriptionStringInput | null,
  rsName?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  propertiesCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeveloperFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeveloperFilterInput | null > | null,
};

export type ModelSubscriptionCourseStudentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  courseID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCourseStudentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCourseStudentFilterInput | null > | null,
};

export type ModelSubscriptionAccountLanguageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  languageID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionAccountLanguageFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountLanguageFilterInput | null > | null,
};

export type ModelSubscriptionPostCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  postID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPostCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostCategoryFilterInput | null > | null,
};

export type ModelSubscriptionQuizQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quizID?: ModelSubscriptionIDInput | null,
  questionID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionQuizQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizQuestionFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateEmailVerificationMutationVariables = {
  input: CreateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type CreateEmailVerificationMutation = {
  createEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateEmailVerificationMutationVariables = {
  input: UpdateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type UpdateEmailVerificationMutation = {
  updateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteEmailVerificationMutationVariables = {
  input: DeleteEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type DeleteEmailVerificationMutation = {
  deleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type CreateSocialLinkMutationVariables = {
  input: CreateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type CreateSocialLinkMutation = {
  createSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type UpdateSocialLinkMutationVariables = {
  input: UpdateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type UpdateSocialLinkMutation = {
  updateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type DeleteSocialLinkMutationVariables = {
  input: DeleteSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type DeleteSocialLinkMutation = {
  deleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: CreateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  input: UpdateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  input: DeleteLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type CreateMenuMutationVariables = {
  input: CreateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type CreateMenuMutation = {
  createMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type UpdateMenuMutationVariables = {
  input: UpdateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type UpdateMenuMutation = {
  updateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type DeleteMenuMutationVariables = {
  input: DeleteMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type DeleteMenuMutation = {
  deleteMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type CreateMenuItemMutationVariables = {
  input: CreateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type CreateMenuItemMutation = {
  createMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type UpdateMenuItemMutationVariables = {
  input: UpdateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type UpdateMenuItemMutation = {
  updateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type DeleteMenuItemMutationVariables = {
  input: DeleteMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type DeleteMenuItemMutation = {
  deleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type CreateTestimonialMutationVariables = {
  input: CreateTestimonialInput,
  condition?: ModelTestimonialConditionInput | null,
};

export type CreateTestimonialMutation = {
  createTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type UpdateTestimonialMutationVariables = {
  input: UpdateTestimonialInput,
  condition?: ModelTestimonialConditionInput | null,
};

export type UpdateTestimonialMutation = {
  updateTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type DeleteTestimonialMutationVariables = {
  input: DeleteTestimonialInput,
  condition?: ModelTestimonialConditionInput | null,
};

export type DeleteTestimonialMutation = {
  deleteTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSlideMutationVariables = {
  input: CreateSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type CreateSlideMutation = {
  createSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type UpdateSlideMutationVariables = {
  input: UpdateSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type UpdateSlideMutation = {
  updateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type DeleteSlideMutationVariables = {
  input: DeleteSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type DeleteSlideMutation = {
  deleteSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type CreateModuleMutationVariables = {
  input: CreateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type CreateModuleMutation = {
  createModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type UpdateModuleMutationVariables = {
  input: UpdateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type UpdateModuleMutation = {
  updateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type DeleteModuleMutationVariables = {
  input: DeleteModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type DeleteModuleMutation = {
  deleteModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type CreateModuleStatusMutationVariables = {
  input: CreateModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type CreateModuleStatusMutation = {
  createModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type UpdateModuleStatusMutationVariables = {
  input: UpdateModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type UpdateModuleStatusMutation = {
  updateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type DeleteModuleStatusMutationVariables = {
  input: DeleteModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type DeleteModuleStatusMutation = {
  deleteModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type CreateQuizMutationVariables = {
  input: CreateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type CreateQuizMutation = {
  createQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type UpdateQuizMutationVariables = {
  input: UpdateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type UpdateQuizMutation = {
  updateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type DeleteQuizMutationVariables = {
  input: DeleteQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type DeleteQuizMutation = {
  deleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type CreateQuizTypeMutationVariables = {
  input: CreateQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type CreateQuizTypeMutation = {
  createQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type UpdateQuizTypeMutationVariables = {
  input: UpdateQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type UpdateQuizTypeMutation = {
  updateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type DeleteQuizTypeMutationVariables = {
  input: DeleteQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type DeleteQuizTypeMutation = {
  deleteQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type CreateQuizStatusMutationVariables = {
  input: CreateQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type CreateQuizStatusMutation = {
  createQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type UpdateQuizStatusMutationVariables = {
  input: UpdateQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type UpdateQuizStatusMutation = {
  updateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type DeleteQuizStatusMutationVariables = {
  input: DeleteQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type DeleteQuizStatusMutation = {
  deleteQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type CreateQuestionTypeMutationVariables = {
  input: CreateQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type CreateQuestionTypeMutation = {
  createQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type UpdateQuestionTypeMutationVariables = {
  input: UpdateQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type UpdateQuestionTypeMutation = {
  updateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type DeleteQuestionTypeMutationVariables = {
  input: DeleteQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type DeleteQuestionTypeMutation = {
  deleteQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  input: DeleteAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type CreatePropertyMutationVariables = {
  input: CreatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type CreatePropertyMutation = {
  createProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type UpdatePropertyMutationVariables = {
  input: UpdatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type UpdatePropertyMutation = {
  updateProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type DeletePropertyMutationVariables = {
  input: DeletePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type DeletePropertyMutation = {
  deleteProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type CreatePropertyTypeMutationVariables = {
  input: CreatePropertyTypeInput,
  condition?: ModelPropertyTypeConditionInput | null,
};

export type CreatePropertyTypeMutation = {
  createPropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type UpdatePropertyTypeMutationVariables = {
  input: UpdatePropertyTypeInput,
  condition?: ModelPropertyTypeConditionInput | null,
};

export type UpdatePropertyTypeMutation = {
  updatePropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type DeletePropertyTypeMutationVariables = {
  input: DeletePropertyTypeInput,
  condition?: ModelPropertyTypeConditionInput | null,
};

export type DeletePropertyTypeMutation = {
  deletePropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type CreatePropertyStatusMutationVariables = {
  input: CreatePropertyStatusInput,
  condition?: ModelPropertyStatusConditionInput | null,
};

export type CreatePropertyStatusMutation = {
  createPropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type UpdatePropertyStatusMutationVariables = {
  input: UpdatePropertyStatusInput,
  condition?: ModelPropertyStatusConditionInput | null,
};

export type UpdatePropertyStatusMutation = {
  updatePropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type DeletePropertyStatusMutationVariables = {
  input: DeletePropertyStatusInput,
  condition?: ModelPropertyStatusConditionInput | null,
};

export type DeletePropertyStatusMutation = {
  deletePropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type CreatePropertyForMutationVariables = {
  input: CreatePropertyForInput,
  condition?: ModelPropertyForConditionInput | null,
};

export type CreatePropertyForMutation = {
  createPropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type UpdatePropertyForMutationVariables = {
  input: UpdatePropertyForInput,
  condition?: ModelPropertyForConditionInput | null,
};

export type UpdatePropertyForMutation = {
  updatePropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type DeletePropertyForMutationVariables = {
  input: DeletePropertyForInput,
  condition?: ModelPropertyForConditionInput | null,
};

export type DeletePropertyForMutation = {
  deletePropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type CreatePropertyFeatureMutationVariables = {
  input: CreatePropertyFeatureInput,
  condition?: ModelPropertyFeatureConditionInput | null,
};

export type CreatePropertyFeatureMutation = {
  createPropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type UpdatePropertyFeatureMutationVariables = {
  input: UpdatePropertyFeatureInput,
  condition?: ModelPropertyFeatureConditionInput | null,
};

export type UpdatePropertyFeatureMutation = {
  updatePropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type DeletePropertyFeatureMutationVariables = {
  input: DeletePropertyFeatureInput,
  condition?: ModelPropertyFeatureConditionInput | null,
};

export type DeletePropertyFeatureMutation = {
  deletePropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type CreateUnitTypeMutationVariables = {
  input: CreateUnitTypeInput,
  condition?: ModelUnitTypeConditionInput | null,
};

export type CreateUnitTypeMutation = {
  createUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type UpdateUnitTypeMutationVariables = {
  input: UpdateUnitTypeInput,
  condition?: ModelUnitTypeConditionInput | null,
};

export type UpdateUnitTypeMutation = {
  updateUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type DeleteUnitTypeMutationVariables = {
  input: DeleteUnitTypeInput,
  condition?: ModelUnitTypeConditionInput | null,
};

export type DeleteUnitTypeMutation = {
  deleteUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type CreateMaterialMutationVariables = {
  input: CreateMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type CreateMaterialMutation = {
  createMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type UpdateMaterialMutationVariables = {
  input: UpdateMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type UpdateMaterialMutation = {
  updateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type DeleteMaterialMutationVariables = {
  input: DeleteMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type DeleteMaterialMutation = {
  deleteMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type CreateAmenityMutationVariables = {
  input: CreateAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type CreateAmenityMutation = {
  createAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type UpdateAmenityMutationVariables = {
  input: UpdateAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type UpdateAmenityMutation = {
  updateAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type DeleteAmenityMutationVariables = {
  input: DeleteAmenityInput,
  condition?: ModelAmenityConditionInput | null,
};

export type DeleteAmenityMutation = {
  deleteAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type CreateCityMutationVariables = {
  input: CreateCityInput,
  condition?: ModelCityConditionInput | null,
};

export type CreateCityMutation = {
  createCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type UpdateCityMutationVariables = {
  input: UpdateCityInput,
  condition?: ModelCityConditionInput | null,
};

export type UpdateCityMutation = {
  updateCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type DeleteCityMutationVariables = {
  input: DeleteCityInput,
  condition?: ModelCityConditionInput | null,
};

export type DeleteCityMutation = {
  deleteCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type CreateAreaMutationVariables = {
  input: CreateAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type CreateAreaMutation = {
  createArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type UpdateAreaMutationVariables = {
  input: UpdateAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type UpdateAreaMutation = {
  updateArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type DeleteAreaMutationVariables = {
  input: DeleteAreaInput,
  condition?: ModelAreaConditionInput | null,
};

export type DeleteAreaMutation = {
  deleteArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type CreateServiceMutation = {
  createService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type CreateSubscriberMutationVariables = {
  input: CreateSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type CreateSubscriberMutation = {
  createSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubscriberMutationVariables = {
  input: UpdateSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type UpdateSubscriberMutation = {
  updateSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubscriberMutationVariables = {
  input: DeleteSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type DeleteSubscriberMutation = {
  deleteSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeveloperMutationVariables = {
  input: CreateDeveloperInput,
  condition?: ModelDeveloperConditionInput | null,
};

export type CreateDeveloperMutation = {
  createDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type UpdateDeveloperMutationVariables = {
  input: UpdateDeveloperInput,
  condition?: ModelDeveloperConditionInput | null,
};

export type UpdateDeveloperMutation = {
  updateDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type DeleteDeveloperMutationVariables = {
  input: DeleteDeveloperInput,
  condition?: ModelDeveloperConditionInput | null,
};

export type DeleteDeveloperMutation = {
  deleteDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type CreateCourseStudentMutationVariables = {
  input: CreateCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type CreateCourseStudentMutation = {
  createCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseStudentMutationVariables = {
  input: UpdateCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type UpdateCourseStudentMutation = {
  updateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseStudentMutationVariables = {
  input: DeleteCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type DeleteCourseStudentMutation = {
  deleteCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountLanguageMutationVariables = {
  input: CreateAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type CreateAccountLanguageMutation = {
  createAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountLanguageMutationVariables = {
  input: UpdateAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type UpdateAccountLanguageMutation = {
  updateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountLanguageMutationVariables = {
  input: DeleteAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type DeleteAccountLanguageMutation = {
  deleteAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostCategoryMutationVariables = {
  input: CreatePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type CreatePostCategoryMutation = {
  createPostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePostCategoryMutationVariables = {
  input: UpdatePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type UpdatePostCategoryMutation = {
  updatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePostCategoryMutationVariables = {
  input: DeletePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type DeletePostCategoryMutation = {
  deletePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuizQuestionMutationVariables = {
  input: CreateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type CreateQuizQuestionMutation = {
  createQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuizQuestionMutationVariables = {
  input: UpdateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type UpdateQuizQuestionMutation = {
  updateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuizQuestionMutationVariables = {
  input: DeleteQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type DeleteQuizQuestionMutation = {
  deleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveUsersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveUsersQuery = {
  activeUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmailVerificationQueryVariables = {
  id: string,
};

export type GetEmailVerificationQuery = {
  getEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type ListEmailVerificationsQueryVariables = {
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailVerificationsQuery = {
  listEmailVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveVerificationsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveVerificationsQuery = {
  activeVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAccountsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAccountsQuery = {
  activeAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSocialLinkQueryVariables = {
  id: string,
};

export type GetSocialLinkQuery = {
  getSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type ListSocialLinksQueryVariables = {
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialLinksQuery = {
  listSocialLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountSocialLinksId?: string | null,
      socialLinkCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveLinksQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveLinksQuery = {
  activeLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountSocialLinksId?: string | null,
      socialLinkCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon: string,
      slug?: string | null,
      isDashboard: string,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      featureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveFeaturesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveFeaturesQuery = {
  activeFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon: string,
      slug?: string | null,
      isDashboard: string,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      featureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLanguageQueryVariables = {
  id: string,
};

export type GetLanguageQuery = {
  getLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type ListLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguagesQuery = {
  listLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveLanguagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveLanguagesQuery = {
  activeLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type ListMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaQuery = {
  listMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      fileurl: string,
      filename: string,
      filetype?: string | null,
      filesize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMediaQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMediaQuery = {
  activeMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      fileurl: string,
      filename: string,
      filetype?: string | null,
      filesize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMenuQueryVariables = {
  id: string,
};

export type GetMenuQuery = {
  getMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type ListMenusQueryVariables = {
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenusQuery = {
  listMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      name: string,
      locations: Array< string | null >,
      items?:  {
        __typename: "ModelMenuItemConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMenusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMenusQuery = {
  activeMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      name: string,
      locations: Array< string | null >,
      items?:  {
        __typename: "ModelMenuItemConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMenuItemQueryVariables = {
  id: string,
};

export type GetMenuItemQuery = {
  getMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type ListMenuItemsQueryVariables = {
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenuItemsQuery = {
  listMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      type: string,
      page?:  {
        __typename: "Page",
        id: string,
        parentId?: string | null,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        slug: string,
        content?: string | null,
        arContent?: string | null,
        frContent?: string | null,
        rsContent?: string | null,
        history?: string | null,
        arHistory?: string | null,
        frHistory?: string | null,
        rsHistory?: string | null,
        values?: string | null,
        arValues?: string | null,
        frValues?: string | null,
        rsValues?: string | null,
        excerpt?: string | null,
        status?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        pageThumbnailId?: string | null,
        pageCreatedById?: string | null,
      } | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuItemsId?: string | null,
      menuItemPageId?: string | null,
      menuItemCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMenuItemsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMenuItemsQuery = {
  activeMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      type: string,
      page?:  {
        __typename: "Page",
        id: string,
        parentId?: string | null,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        slug: string,
        content?: string | null,
        arContent?: string | null,
        frContent?: string | null,
        rsContent?: string | null,
        history?: string | null,
        arHistory?: string | null,
        frHistory?: string | null,
        rsHistory?: string | null,
        values?: string | null,
        arValues?: string | null,
        frValues?: string | null,
        rsValues?: string | null,
        excerpt?: string | null,
        status?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        pageThumbnailId?: string | null,
        pageCreatedById?: string | null,
      } | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuItemsId?: string | null,
      menuItemPageId?: string | null,
      menuItemCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePagesQuery = {
  activePages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveCategoriesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveCategoriesQuery = {
  activeCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePostsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePostsQuery = {
  activePosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestimonialQueryVariables = {
  id: string,
};

export type GetTestimonialQuery = {
  getTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type ListTestimonialsQueryVariables = {
  filter?: ModelTestimonialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestimonialsQuery = {
  listTestimonials?:  {
    __typename: "ModelTestimonialConnection",
    items:  Array< {
      __typename: "Testimonial",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      testimonialThumbnailId?: string | null,
      testimonialCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveTestimonialsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTestimonialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveTestimonialsQuery = {
  activeTestimonials?:  {
    __typename: "ModelTestimonialConnection",
    items:  Array< {
      __typename: "Testimonial",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      testimonialThumbnailId?: string | null,
      testimonialCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      name: string,
      email: string,
      subject?: string | null,
      message: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMessagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMessagesQuery = {
  activeMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      name: string,
      email: string,
      subject?: string | null,
      message: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSlideQueryVariables = {
  id: string,
};

export type GetSlideQuery = {
  getSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type ListSlidesQueryVariables = {
  filter?: ModelSlideFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSlidesQuery = {
  listSlides?:  {
    __typename: "ModelSlideConnection",
    items:  Array< {
      __typename: "Slide",
      id: string,
      title: string,
      precedence: string,
      content?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      mobImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      slideImageId?: string | null,
      slideMobImageId?: string | null,
      slideCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveSlidesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSlideFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveSlidesQuery = {
  activeSlides?:  {
    __typename: "ModelSlideConnection",
    items:  Array< {
      __typename: "Slide",
      id: string,
      title: string,
      precedence: string,
      content?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      mobImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      slideImageId?: string | null,
      slideMobImageId?: string | null,
      slideCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveCoursesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveCoursesQuery = {
  activeCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleQueryVariables = {
  id: string,
};

export type GetModuleQuery = {
  getModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type ListModulesQueryVariables = {
  filter?: ModelModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModulesQuery = {
  listModules?:  {
    __typename: "ModelModuleConnection",
    items:  Array< {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveModulesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveModulesQuery = {
  activeModules?:  {
    __typename: "ModelModuleConnection",
    items:  Array< {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleStatusQueryVariables = {
  id: string,
};

export type GetModuleStatusQuery = {
  getModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type ListModuleStatusesQueryVariables = {
  filter?: ModelModuleStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModuleStatusesQuery = {
  listModuleStatuses?:  {
    __typename: "ModelModuleStatusConnection",
    items:  Array< {
      __typename: "ModuleStatus",
      id: string,
      module:  {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      deleted?: string | null,
      isCompleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      moduleStatusModuleId: string,
      moduleStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveModuleStatusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModuleStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveModuleStatusQuery = {
  activeModuleStatus?:  {
    __typename: "ModelModuleStatusConnection",
    items:  Array< {
      __typename: "ModuleStatus",
      id: string,
      module:  {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      deleted?: string | null,
      isCompleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      moduleStatusModuleId: string,
      moduleStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQueryVariables = {
  id: string,
};

export type GetQuizQuery = {
  getQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type ListQuizzesQueryVariables = {
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizzesQuery = {
  listQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizzesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizzesQuery = {
  activeQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizTypeQueryVariables = {
  id: string,
};

export type GetQuizTypeQuery = {
  getQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type ListQuizTypesQueryVariables = {
  filter?: ModelQuizTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizTypesQuery = {
  listQuizTypes?:  {
    __typename: "ModelQuizTypeConnection",
    items:  Array< {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizTypesQuery = {
  activeQuizTypes?:  {
    __typename: "ModelQuizTypeConnection",
    items:  Array< {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizStatusQueryVariables = {
  id: string,
};

export type GetQuizStatusQuery = {
  getQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type ListQuizStatusesQueryVariables = {
  filter?: ModelQuizStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizStatusesQuery = {
  listQuizStatuses?:  {
    __typename: "ModelQuizStatusConnection",
    items:  Array< {
      __typename: "QuizStatus",
      id: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      isCompleted: boolean,
      totalScore: number,
      studentScore: number,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      quizStatusQuizId: string,
      quizStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizStatusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizStatusQuery = {
  activeQuizStatus?:  {
    __typename: "ModelQuizStatusConnection",
    items:  Array< {
      __typename: "QuizStatus",
      id: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      isCompleted: boolean,
      totalScore: number,
      studentScore: number,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      quizStatusQuizId: string,
      quizStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionTypeQueryVariables = {
  id: string,
};

export type GetQuestionTypeQuery = {
  getQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type ListQuestionTypesQueryVariables = {
  filter?: ModelQuestionTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionTypesQuery = {
  listQuestionTypes?:  {
    __typename: "ModelQuestionTypeConnection",
    items:  Array< {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuestionTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuestionTypesQuery = {
  activeQuestionTypes?:  {
    __typename: "ModelQuestionTypeConnection",
    items:  Array< {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuestionsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuestionsQuery = {
  activeQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnswerQueryVariables = {
  id: string,
};

export type GetAnswerQuery = {
  getAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type ListAnswersQueryVariables = {
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersQuery = {
  listAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        answer: string,
        arAnswer?: string | null,
        frAnswer?: string | null,
        rsAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      deleted?: string | null,
      isCorrect: boolean,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAnswersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAnswersQuery = {
  activeAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        answer: string,
        arAnswer?: string | null,
        frAnswer?: string | null,
        rsAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      deleted?: string | null,
      isCorrect: boolean,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyQueryVariables = {
  id: string,
};

export type GetPropertyQuery = {
  getProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type ListPropertiesQueryVariables = {
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertiesQuery = {
  listProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      slug: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      projectSlug?: string | null,
      areaSlug?: string | null,
      baths: number,
      beds: number,
      area: number,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      virtualImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertyFor?: string | null,
      type?: string | null,
      city?: string | null,
      gallery?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      featured?: boolean | null,
      garage?: number | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      price?: number | null,
      garageSize?: number | null,
      propertyID?: string | null,
      video?: string | null,
      features?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyImageId?: string | null,
      propertyVirtualImageId?: string | null,
      propertyCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByProjectQueryVariables = {
  projectSlug: string,
  createdAtDeleted?: ModelPropertyByProjectCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByProjectQuery = {
  propertiesByProject?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      slug: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      projectSlug?: string | null,
      areaSlug?: string | null,
      baths: number,
      beds: number,
      area: number,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      virtualImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertyFor?: string | null,
      type?: string | null,
      city?: string | null,
      gallery?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      featured?: boolean | null,
      garage?: number | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      price?: number | null,
      garageSize?: number | null,
      propertyID?: string | null,
      video?: string | null,
      features?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyImageId?: string | null,
      propertyVirtualImageId?: string | null,
      propertyCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByAreaQueryVariables = {
  areaSlug: string,
  createdAtDeleted?: ModelPropertyByAreaCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByAreaQuery = {
  propertiesByArea?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      slug: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      projectSlug?: string | null,
      areaSlug?: string | null,
      baths: number,
      beds: number,
      area: number,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      virtualImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertyFor?: string | null,
      type?: string | null,
      city?: string | null,
      gallery?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      featured?: boolean | null,
      garage?: number | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      price?: number | null,
      garageSize?: number | null,
      propertyID?: string | null,
      video?: string | null,
      features?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyImageId?: string | null,
      propertyVirtualImageId?: string | null,
      propertyCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePropertiesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePropertiesQuery = {
  activeProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      slug: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      projectSlug?: string | null,
      areaSlug?: string | null,
      baths: number,
      beds: number,
      area: number,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      virtualImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertyFor?: string | null,
      type?: string | null,
      city?: string | null,
      gallery?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      featured?: boolean | null,
      garage?: number | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      price?: number | null,
      garageSize?: number | null,
      propertyID?: string | null,
      video?: string | null,
      features?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyImageId?: string | null,
      propertyVirtualImageId?: string | null,
      propertyCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyTypeQueryVariables = {
  id: string,
};

export type GetPropertyTypeQuery = {
  getPropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type ListPropertyTypesQueryVariables = {
  filter?: ModelPropertyTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyTypesQuery = {
  listPropertyTypes?:  {
    __typename: "ModelPropertyTypeConnection",
    items:  Array< {
      __typename: "PropertyType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      parentId?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyTypeImageId?: string | null,
      propertyTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePropertyTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePropertyTypesQuery = {
  activePropertyTypes?:  {
    __typename: "ModelPropertyTypeConnection",
    items:  Array< {
      __typename: "PropertyType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      parentId?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyTypeImageId?: string | null,
      propertyTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyStatusQueryVariables = {
  id: string,
};

export type GetPropertyStatusQuery = {
  getPropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type ListPropertyStatusesQueryVariables = {
  filter?: ModelPropertyStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyStatusesQuery = {
  listPropertyStatuses?:  {
    __typename: "ModelPropertyStatusConnection",
    items:  Array< {
      __typename: "PropertyStatus",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyStatusCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePropertyStatusesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePropertyStatusesQuery = {
  activePropertyStatuses?:  {
    __typename: "ModelPropertyStatusConnection",
    items:  Array< {
      __typename: "PropertyStatus",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyStatusCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyForQueryVariables = {
  id: string,
};

export type GetPropertyForQuery = {
  getPropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type ListPropertyForsQueryVariables = {
  filter?: ModelPropertyForFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyForsQuery = {
  listPropertyFors?:  {
    __typename: "ModelPropertyForConnection",
    items:  Array< {
      __typename: "PropertyFor",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyForCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePropertyPurposesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyForFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePropertyPurposesQuery = {
  activePropertyPurposes?:  {
    __typename: "ModelPropertyForConnection",
    items:  Array< {
      __typename: "PropertyFor",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyForCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyFeatureQueryVariables = {
  id: string,
};

export type GetPropertyFeatureQuery = {
  getPropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type ListPropertyFeaturesQueryVariables = {
  filter?: ModelPropertyFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyFeaturesQuery = {
  listPropertyFeatures?:  {
    __typename: "ModelPropertyFeatureConnection",
    items:  Array< {
      __typename: "PropertyFeature",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyFeatureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePropertyFeaturesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePropertyFeaturesQuery = {
  activePropertyFeatures?:  {
    __typename: "ModelPropertyFeatureConnection",
    items:  Array< {
      __typename: "PropertyFeature",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      propertyFeatureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByTitleQueryVariables = {
  title: string,
  createdAtDeleted?: ModelProjectByTitleCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByTitleQuery = {
  projectsByTitle?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsBySlugQueryVariables = {
  slug: string,
  createdAtDeleted?: ModelProjectBySlugCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsBySlugQuery = {
  projectsBySlug?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByCityQueryVariables = {
  city: string,
  createdAtDeleted?: ModelProjectByCityCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByCityQuery = {
  projectsByCity?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByAreaQueryVariables = {
  areaSlug: string,
  createdAtDeleted?: ModelProjectByAreaCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByAreaQuery = {
  projectsByArea?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveProjectsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveProjectsQuery = {
  activeProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      tagline?: string | null,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      slug: string,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      resAddress?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      city?: string | null,
      areaSlug?: string | null,
      exteriors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      exteriorsGallery?: Array< string | null > | null,
      exteriorsDescription?: string | null,
      arExteriorsDescription?: string | null,
      frExteriorsDescription?: string | null,
      rsExteriorsDescription?: string | null,
      interiors?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      interiorsGallery?: Array< string | null > | null,
      interiorsDescription?: string | null,
      arInteriorsDescription?: string | null,
      frInteriorsDescription?: string | null,
      rsInteriorsDescription?: string | null,
      video?: string | null,
      yearBuilt?: number | null,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      projectImageId?: string | null,
      projectLogoId?: string | null,
      projectCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUnitTypeQueryVariables = {
  id: string,
};

export type GetUnitTypeQuery = {
  getUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type ListUnitTypesQueryVariables = {
  filter?: ModelUnitTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitTypesQuery = {
  listUnitTypes?:  {
    __typename: "ModelUnitTypeConnection",
    items:  Array< {
      __typename: "UnitType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      parentId?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      vrImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      unitTypeImageId?: string | null,
      unitTypeVrImageId?: string | null,
      unitTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveUnitTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUnitTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveUnitTypesQuery = {
  activeUnitTypes?:  {
    __typename: "ModelUnitTypeConnection",
    items:  Array< {
      __typename: "UnitType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      parentId?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      vrImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      unitTypeImageId?: string | null,
      unitTypeVrImageId?: string | null,
      unitTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMaterialQueryVariables = {
  id: string,
};

export type GetMaterialQuery = {
  getMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type ListMaterialsQueryVariables = {
  filter?: ModelMaterialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMaterialsQuery = {
  listMaterials?:  {
    __typename: "ModelMaterialConnection",
    items:  Array< {
      __typename: "Material",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      materialImageId?: string | null,
      materialCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMaterialsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMaterialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMaterialsQuery = {
  activeMaterials?:  {
    __typename: "ModelMaterialConnection",
    items:  Array< {
      __typename: "Material",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      materialImageId?: string | null,
      materialCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAmenityQueryVariables = {
  id: string,
};

export type GetAmenityQuery = {
  getAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type ListAmenitiesQueryVariables = {
  filter?: ModelAmenityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmenitiesQuery = {
  listAmenities?:  {
    __typename: "ModelAmenityConnection",
    items:  Array< {
      __typename: "Amenity",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      amenityImageId?: string | null,
      amenityCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAmenitiesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAmenityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAmenitiesQuery = {
  activeAmenities?:  {
    __typename: "ModelAmenityConnection",
    items:  Array< {
      __typename: "Amenity",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      amenityImageId?: string | null,
      amenityCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCityQueryVariables = {
  id: string,
};

export type GetCityQuery = {
  getCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type ListCitiesQueryVariables = {
  filter?: ModelCityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCitiesQuery = {
  listCities?:  {
    __typename: "ModelCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      country: string,
      state: string,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      cityImageId?: string | null,
      cityCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveCitiesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveCitiesQuery = {
  activeCities?:  {
    __typename: "ModelCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      country: string,
      state: string,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      cityImageId?: string | null,
      cityCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAreaQueryVariables = {
  id: string,
};

export type GetAreaQuery = {
  getArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type ListAreasQueryVariables = {
  filter?: ModelAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAreasQuery = {
  listAreas?:  {
    __typename: "ModelAreaConnection",
    items:  Array< {
      __typename: "Area",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      citySlug: string,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      areaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AreasByCityQueryVariables = {
  citySlug: string,
  createdAtDeleted?: ModelAreaByCityCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AreasByCityQuery = {
  areasByCity?:  {
    __typename: "ModelAreaConnection",
    items:  Array< {
      __typename: "Area",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      citySlug: string,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      areaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAreasQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAreasQuery = {
  activeAreas?:  {
    __typename: "ModelAreaConnection",
    items:  Array< {
      __typename: "Area",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      citySlug: string,
      zipCode?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      areaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceQueryVariables = {
  id: string,
};

export type GetServiceQuery = {
  getService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type ListServicesQueryVariables = {
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServicesQuery = {
  listServices?:  {
    __typename: "ModelServiceConnection",
    items:  Array< {
      __typename: "Service",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      serviceImageId?: string | null,
      serviceCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveServicesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveServicesQuery = {
  activeServices?:  {
    __typename: "ModelServiceConnection",
    items:  Array< {
      __typename: "Service",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      serviceImageId?: string | null,
      serviceCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubscriberQueryVariables = {
  id: string,
};

export type GetSubscriberQuery = {
  getSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubscribersQueryVariables = {
  filter?: ModelSubscriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubscribersQuery = {
  listSubscribers?:  {
    __typename: "ModelSubscriberConnection",
    items:  Array< {
      __typename: "Subscriber",
      id: string,
      email: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveSubscribersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubscriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveSubscribersQuery = {
  activeSubscribers?:  {
    __typename: "ModelSubscriberConnection",
    items:  Array< {
      __typename: "Subscriber",
      id: string,
      email: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeveloperQueryVariables = {
  id: string,
};

export type GetDeveloperQuery = {
  getDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type ListDevelopersQueryVariables = {
  filter?: ModelDeveloperFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevelopersQuery = {
  listDevelopers?:  {
    __typename: "ModelDeveloperConnection",
    items:  Array< {
      __typename: "Developer",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      developerImageId?: string | null,
      developerCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveDevelopersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeveloperFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveDevelopersQuery = {
  activeDevelopers?:  {
    __typename: "ModelDeveloperConnection",
    items:  Array< {
      __typename: "Developer",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      slug: string,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      propertiesCount?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      developerImageId?: string | null,
      developerCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseStudentQueryVariables = {
  id: string,
};

export type GetCourseStudentQuery = {
  getCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCourseStudentsQueryVariables = {
  filter?: ModelCourseStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseStudentsQuery = {
  listCourseStudents?:  {
    __typename: "ModelCourseStudentConnection",
    items:  Array< {
      __typename: "CourseStudent",
      id: string,
      userID: string,
      courseID: string,
      user:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      course:  {
        __typename: "Course",
        id: string,
        name: string,
        slug: string,
        description?: string | null,
        prerequisiteQuiz?: string | null,
        prerequisiteQuizTitle?: string | null,
        prerequisiteQuizSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        prerequisiteModuleId?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseImageId?: string | null,
        courseCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountLanguageQueryVariables = {
  id: string,
};

export type GetAccountLanguageQuery = {
  getAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountLanguagesQueryVariables = {
  filter?: ModelAccountLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountLanguagesQuery = {
  listAccountLanguages?:  {
    __typename: "ModelAccountLanguageConnection",
    items:  Array< {
      __typename: "AccountLanguage",
      id: string,
      accountID: string,
      languageID: string,
      account:  {
        __typename: "Account",
        id: string,
        domain: string,
        siteTitle: string,
        arSiteTitle: string,
        frSiteTitle: string,
        rsSiteTitle: string,
        tagline: string,
        arTagline?: string | null,
        frTagline?: string | null,
        rsTagline?: string | null,
        description: string,
        arDescription?: string | null,
        frDescription?: string | null,
        rsDescription?: string | null,
        address: string,
        arAddress?: string | null,
        frAddress?: string | null,
        rsAddress?: string | null,
        workingHours?: string | null,
        arWorkingHours?: string | null,
        frWorkingHours?: string | null,
        rsWorkingHours?: string | null,
        email: string,
        phone_number: string,
        siteAddress: string,
        defaultLanguage: string,
        status: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountLogoId?: string | null,
        accountCreatedById?: string | null,
      },
      language:  {
        __typename: "Language",
        id: string,
        name: string,
        code: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        languageCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostCategoryQueryVariables = {
  id: string,
};

export type GetPostCategoryQuery = {
  getPostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPostCategoriesQueryVariables = {
  filter?: ModelPostCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostCategoriesQuery = {
  listPostCategories?:  {
    __typename: "ModelPostCategoryConnection",
    items:  Array< {
      __typename: "PostCategory",
      id: string,
      categoryID: string,
      postID: string,
      category:  {
        __typename: "Category",
        id: string,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        slug: string,
        type: string,
        description?: string | null,
        arDescription?: string | null,
        frDescription?: string | null,
        rsDescription?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        categoryThumbnailId?: string | null,
        categoryCreatedById?: string | null,
      },
      post:  {
        __typename: "Post",
        id: string,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        slug: string,
        content?: string | null,
        arContent?: string | null,
        frContent?: string | null,
        rsContent?: string | null,
        excerpt?: string | null,
        status?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        postThumbnailId?: string | null,
        postCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQuestionQueryVariables = {
  id: string,
};

export type GetQuizQuestionQuery = {
  getQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuizQuestionsQueryVariables = {
  filter?: ModelQuizQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizQuestionsQuery = {
  listQuizQuestions?:  {
    __typename: "ModelQuizQuestionConnection",
    items:  Array< {
      __typename: "QuizQuestion",
      id: string,
      quizID: string,
      questionID: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        arTitle?: string | null,
        frTitle?: string | null,
        rsTitle?: string | null,
        answer: string,
        arAnswer?: string | null,
        frAnswer?: string | null,
        rsAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnCreateEmailVerificationSubscription = {
  onCreateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnUpdateEmailVerificationSubscription = {
  onUpdateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmailVerificationSubscriptionVariables = {
  filter?: ModelSubscriptionEmailVerificationFilterInput | null,
};

export type OnDeleteEmailVerificationSubscription = {
  onDeleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    domain: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    arSiteTitle: string,
    frSiteTitle: string,
    rsSiteTitle: string,
    tagline: string,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    workingHours?: string | null,
    arWorkingHours?: string | null,
    frWorkingHours?: string | null,
    rsWorkingHours?: string | null,
    email: string,
    phone_number: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnCreateSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnCreateSocialLinkSubscription = {
  onCreateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnUpdateSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnUpdateSocialLinkSubscription = {
  onUpdateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnDeleteSocialLinkSubscriptionVariables = {
  filter?: ModelSubscriptionSocialLinkFilterInput | null,
};

export type OnDeleteSocialLinkSubscription = {
  onDeleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnCreateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnUpdateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnDeleteFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnCreateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnCreateLanguageSubscription = {
  onCreateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnUpdateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnUpdateLanguageSubscription = {
  onUpdateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnDeleteLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnDeleteLanguageSubscription = {
  onDeleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnCreateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnUpdateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnDeleteMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    propertyGalleryId?: string | null,
    projectExteriorsId?: string | null,
    projectInteriorsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnCreateMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnCreateMenuSubscription = {
  onCreateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnUpdateMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnUpdateMenuSubscription = {
  onUpdateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnDeleteMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnDeleteMenuSubscription = {
  onDeleteMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnCreateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnCreateMenuItemSubscription = {
  onCreateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnUpdateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnUpdateMenuItemSubscription = {
  onUpdateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnDeleteMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnDeleteMenuItemSubscription = {
  onDeleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      parentId?: string | null,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      history?: string | null,
      arHistory?: string | null,
      frHistory?: string | null,
      rsHistory?: string | null,
      values?: string | null,
      arValues?: string | null,
      frValues?: string | null,
      rsValues?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnCreatePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnUpdatePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnDeletePageSubscriptionVariables = {
  filter?: ModelSubscriptionPageFilterInput | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    parentId?: string | null,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    history?: string | null,
    arHistory?: string | null,
    frHistory?: string | null,
    rsHistory?: string | null,
    values?: string | null,
    arValues?: string | null,
    frValues?: string | null,
    rsValues?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    type: string,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnCreateTestimonialSubscriptionVariables = {
  filter?: ModelSubscriptionTestimonialFilterInput | null,
};

export type OnCreateTestimonialSubscription = {
  onCreateTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type OnUpdateTestimonialSubscriptionVariables = {
  filter?: ModelSubscriptionTestimonialFilterInput | null,
};

export type OnUpdateTestimonialSubscription = {
  onUpdateTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type OnDeleteTestimonialSubscriptionVariables = {
  filter?: ModelSubscriptionTestimonialFilterInput | null,
};

export type OnDeleteTestimonialSubscription = {
  onDeleteTestimonial?:  {
    __typename: "Testimonial",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    slug: string,
    content?: string | null,
    arContent?: string | null,
    frContent?: string | null,
    rsContent?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    testimonialThumbnailId?: string | null,
    testimonialCreatedById?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSlideSubscriptionVariables = {
  filter?: ModelSubscriptionSlideFilterInput | null,
};

export type OnCreateSlideSubscription = {
  onCreateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnUpdateSlideSubscriptionVariables = {
  filter?: ModelSubscriptionSlideFilterInput | null,
};

export type OnUpdateSlideSubscription = {
  onUpdateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnDeleteSlideSubscriptionVariables = {
  filter?: ModelSubscriptionSlideFilterInput | null,
};

export type OnDeleteSlideSubscription = {
  onDeleteSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnCreateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnUpdateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnDeleteCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnCreateModuleSubscriptionVariables = {
  filter?: ModelSubscriptionModuleFilterInput | null,
};

export type OnCreateModuleSubscription = {
  onCreateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnUpdateModuleSubscriptionVariables = {
  filter?: ModelSubscriptionModuleFilterInput | null,
};

export type OnUpdateModuleSubscription = {
  onUpdateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnDeleteModuleSubscriptionVariables = {
  filter?: ModelSubscriptionModuleFilterInput | null,
};

export type OnDeleteModuleSubscription = {
  onDeleteModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnCreateModuleStatusSubscriptionVariables = {
  filter?: ModelSubscriptionModuleStatusFilterInput | null,
};

export type OnCreateModuleStatusSubscription = {
  onCreateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnUpdateModuleStatusSubscriptionVariables = {
  filter?: ModelSubscriptionModuleStatusFilterInput | null,
};

export type OnUpdateModuleStatusSubscription = {
  onUpdateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnDeleteModuleStatusSubscriptionVariables = {
  filter?: ModelSubscriptionModuleStatusFilterInput | null,
};

export type OnDeleteModuleStatusSubscription = {
  onDeleteModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnCreateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
};

export type OnCreateQuizSubscription = {
  onCreateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
};

export type OnUpdateQuizSubscription = {
  onUpdateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
};

export type OnDeleteQuizSubscription = {
  onDeleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnCreateQuizTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuizTypeFilterInput | null,
};

export type OnCreateQuizTypeSubscription = {
  onCreateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuizTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuizTypeFilterInput | null,
};

export type OnUpdateQuizTypeSubscription = {
  onUpdateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuizTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuizTypeFilterInput | null,
};

export type OnDeleteQuizTypeSubscription = {
  onDeleteQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnCreateQuizStatusSubscriptionVariables = {
  filter?: ModelSubscriptionQuizStatusFilterInput | null,
};

export type OnCreateQuizStatusSubscription = {
  onCreateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnUpdateQuizStatusSubscriptionVariables = {
  filter?: ModelSubscriptionQuizStatusFilterInput | null,
};

export type OnUpdateQuizStatusSubscription = {
  onUpdateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnDeleteQuizStatusSubscriptionVariables = {
  filter?: ModelSubscriptionQuizStatusFilterInput | null,
};

export type OnDeleteQuizStatusSubscription = {
  onDeleteQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnCreateQuestionTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTypeFilterInput | null,
};

export type OnCreateQuestionTypeSubscription = {
  onCreateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuestionTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTypeFilterInput | null,
};

export type OnUpdateQuestionTypeSubscription = {
  onUpdateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuestionTypeSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTypeFilterInput | null,
};

export type OnDeleteQuestionTypeSubscription = {
  onDeleteQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    answer: string,
    arAnswer?: string | null,
    frAnswer?: string | null,
    rsAnswer?: string | null,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      arName?: string | null,
      frName?: string | null,
      rsName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnCreateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
};

export type OnCreateAnswerSubscription = {
  onCreateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnUpdateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
};

export type OnUpdateAnswerSubscription = {
  onUpdateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnDeleteAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
};

export type OnDeleteAnswerSubscription = {
  onDeleteAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    deleted?: string | null,
    isCorrect: boolean,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnCreatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnCreatePropertySubscription = {
  onCreateProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type OnUpdatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnUpdatePropertySubscription = {
  onUpdateProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type OnDeletePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
};

export type OnDeletePropertySubscription = {
  onDeleteProperty?:  {
    __typename: "Property",
    id: string,
    slug: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    rsAddress?: string | null,
    projectSlug?: string | null,
    areaSlug?: string | null,
    baths: number,
    beds: number,
    area: number,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    virtualImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertyFor?: string | null,
    type?: string | null,
    city?: string | null,
    gallery?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    featured?: boolean | null,
    garage?: number | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    price?: number | null,
    garageSize?: number | null,
    propertyID?: string | null,
    video?: string | null,
    features?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyImageId?: string | null,
    propertyVirtualImageId?: string | null,
    propertyCreatedById?: string | null,
  } | null,
};

export type OnCreatePropertyTypeSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyTypeFilterInput | null,
};

export type OnCreatePropertyTypeSubscription = {
  onCreatePropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdatePropertyTypeSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyTypeFilterInput | null,
};

export type OnUpdatePropertyTypeSubscription = {
  onUpdatePropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type OnDeletePropertyTypeSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyTypeFilterInput | null,
};

export type OnDeletePropertyTypeSubscription = {
  onDeletePropertyType?:  {
    __typename: "PropertyType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyTypeImageId?: string | null,
    propertyTypeCreatedById?: string | null,
  } | null,
};

export type OnCreatePropertyStatusSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyStatusFilterInput | null,
};

export type OnCreatePropertyStatusSubscription = {
  onCreatePropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type OnUpdatePropertyStatusSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyStatusFilterInput | null,
};

export type OnUpdatePropertyStatusSubscription = {
  onUpdatePropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type OnDeletePropertyStatusSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyStatusFilterInput | null,
};

export type OnDeletePropertyStatusSubscription = {
  onDeletePropertyStatus?:  {
    __typename: "PropertyStatus",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyStatusCreatedById?: string | null,
  } | null,
};

export type OnCreatePropertyForSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyForFilterInput | null,
};

export type OnCreatePropertyForSubscription = {
  onCreatePropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type OnUpdatePropertyForSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyForFilterInput | null,
};

export type OnUpdatePropertyForSubscription = {
  onUpdatePropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type OnDeletePropertyForSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyForFilterInput | null,
};

export type OnDeletePropertyForSubscription = {
  onDeletePropertyFor?:  {
    __typename: "PropertyFor",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyForCreatedById?: string | null,
  } | null,
};

export type OnCreatePropertyFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFeatureFilterInput | null,
};

export type OnCreatePropertyFeatureSubscription = {
  onCreatePropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type OnUpdatePropertyFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFeatureFilterInput | null,
};

export type OnUpdatePropertyFeatureSubscription = {
  onUpdatePropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type OnDeletePropertyFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFeatureFilterInput | null,
};

export type OnDeletePropertyFeatureSubscription = {
  onDeletePropertyFeature?:  {
    __typename: "PropertyFeature",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    propertyFeatureCreatedById?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    title: string,
    arTitle?: string | null,
    frTitle?: string | null,
    rsTitle?: string | null,
    tagline?: string | null,
    arTagline?: string | null,
    frTagline?: string | null,
    rsTagline?: string | null,
    description?: string | null,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    slug: string,
    address: string,
    arAddress?: string | null,
    frAddress?: string | null,
    resAddress?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    city?: string | null,
    areaSlug?: string | null,
    exteriors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    exteriorsGallery?: Array< string | null > | null,
    exteriorsDescription?: string | null,
    arExteriorsDescription?: string | null,
    frExteriorsDescription?: string | null,
    rsExteriorsDescription?: string | null,
    interiors?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    interiorsGallery?: Array< string | null > | null,
    interiorsDescription?: string | null,
    arInteriorsDescription?: string | null,
    frInteriorsDescription?: string | null,
    rsInteriorsDescription?: string | null,
    video?: string | null,
    yearBuilt?: number | null,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    projectImageId?: string | null,
    projectLogoId?: string | null,
    projectCreatedById?: string | null,
  } | null,
};

export type OnCreateUnitTypeSubscriptionVariables = {
  filter?: ModelSubscriptionUnitTypeFilterInput | null,
};

export type OnCreateUnitTypeSubscription = {
  onCreateUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdateUnitTypeSubscriptionVariables = {
  filter?: ModelSubscriptionUnitTypeFilterInput | null,
};

export type OnUpdateUnitTypeSubscription = {
  onUpdateUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type OnDeleteUnitTypeSubscriptionVariables = {
  filter?: ModelSubscriptionUnitTypeFilterInput | null,
};

export type OnDeleteUnitTypeSubscription = {
  onDeleteUnitType?:  {
    __typename: "UnitType",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    parentId?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    vrImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    unitTypeImageId?: string | null,
    unitTypeVrImageId?: string | null,
    unitTypeCreatedById?: string | null,
  } | null,
};

export type OnCreateMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnCreateMaterialSubscription = {
  onCreateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type OnUpdateMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnUpdateMaterialSubscription = {
  onUpdateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type OnDeleteMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnDeleteMaterialSubscription = {
  onDeleteMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    materialImageId?: string | null,
    materialCreatedById?: string | null,
  } | null,
};

export type OnCreateAmenitySubscriptionVariables = {
  filter?: ModelSubscriptionAmenityFilterInput | null,
};

export type OnCreateAmenitySubscription = {
  onCreateAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type OnUpdateAmenitySubscriptionVariables = {
  filter?: ModelSubscriptionAmenityFilterInput | null,
};

export type OnUpdateAmenitySubscription = {
  onUpdateAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type OnDeleteAmenitySubscriptionVariables = {
  filter?: ModelSubscriptionAmenityFilterInput | null,
};

export type OnDeleteAmenitySubscription = {
  onDeleteAmenity?:  {
    __typename: "Amenity",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    amenityImageId?: string | null,
    amenityCreatedById?: string | null,
  } | null,
};

export type OnCreateCitySubscriptionVariables = {
  filter?: ModelSubscriptionCityFilterInput | null,
};

export type OnCreateCitySubscription = {
  onCreateCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type OnUpdateCitySubscriptionVariables = {
  filter?: ModelSubscriptionCityFilterInput | null,
};

export type OnUpdateCitySubscription = {
  onUpdateCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type OnDeleteCitySubscriptionVariables = {
  filter?: ModelSubscriptionCityFilterInput | null,
};

export type OnDeleteCitySubscription = {
  onDeleteCity?:  {
    __typename: "City",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    country: string,
    state: string,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    cityImageId?: string | null,
    cityCreatedById?: string | null,
  } | null,
};

export type OnCreateAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnCreateAreaSubscription = {
  onCreateArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type OnUpdateAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnUpdateAreaSubscription = {
  onUpdateArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type OnDeleteAreaSubscriptionVariables = {
  filter?: ModelSubscriptionAreaFilterInput | null,
};

export type OnDeleteAreaSubscription = {
  onDeleteArea?:  {
    __typename: "Area",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    citySlug: string,
    zipCode?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    areaCreatedById?: string | null,
  } | null,
};

export type OnCreateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnCreateServiceSubscription = {
  onCreateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type OnUpdateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnUpdateServiceSubscription = {
  onUpdateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type OnDeleteServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnDeleteServiceSubscription = {
  onDeleteService?:  {
    __typename: "Service",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    description: string,
    arDescription?: string | null,
    frDescription?: string | null,
    rsDescription?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    serviceImageId?: string | null,
    serviceCreatedById?: string | null,
  } | null,
};

export type OnCreateSubscriberSubscriptionVariables = {
  filter?: ModelSubscriptionSubscriberFilterInput | null,
};

export type OnCreateSubscriberSubscription = {
  onCreateSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubscriberSubscriptionVariables = {
  filter?: ModelSubscriptionSubscriberFilterInput | null,
};

export type OnUpdateSubscriberSubscription = {
  onUpdateSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubscriberSubscriptionVariables = {
  filter?: ModelSubscriptionSubscriberFilterInput | null,
};

export type OnDeleteSubscriberSubscription = {
  onDeleteSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    email: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeveloperSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperFilterInput | null,
};

export type OnCreateDeveloperSubscription = {
  onCreateDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type OnUpdateDeveloperSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperFilterInput | null,
};

export type OnUpdateDeveloperSubscription = {
  onUpdateDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type OnDeleteDeveloperSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperFilterInput | null,
};

export type OnDeleteDeveloperSubscription = {
  onDeleteDeveloper?:  {
    __typename: "Developer",
    id: string,
    name: string,
    arName?: string | null,
    frName?: string | null,
    rsName?: string | null,
    slug: string,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      propertyGalleryId?: string | null,
      projectExteriorsId?: string | null,
      projectInteriorsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    propertiesCount?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    developerImageId?: string | null,
    developerCreatedById?: string | null,
  } | null,
};

export type OnCreateCourseStudentSubscriptionVariables = {
  filter?: ModelSubscriptionCourseStudentFilterInput | null,
};

export type OnCreateCourseStudentSubscription = {
  onCreateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseStudentSubscriptionVariables = {
  filter?: ModelSubscriptionCourseStudentFilterInput | null,
};

export type OnUpdateCourseStudentSubscription = {
  onUpdateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseStudentSubscriptionVariables = {
  filter?: ModelSubscriptionCourseStudentFilterInput | null,
};

export type OnDeleteCourseStudentSubscription = {
  onDeleteCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionAccountLanguageFilterInput | null,
};

export type OnCreateAccountLanguageSubscription = {
  onCreateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionAccountLanguageFilterInput | null,
};

export type OnUpdateAccountLanguageSubscription = {
  onUpdateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionAccountLanguageFilterInput | null,
};

export type OnDeleteAccountLanguageSubscription = {
  onDeleteAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      domain: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      arSiteTitle: string,
      frSiteTitle: string,
      rsSiteTitle: string,
      tagline: string,
      arTagline?: string | null,
      frTagline?: string | null,
      rsTagline?: string | null,
      description: string,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      address: string,
      arAddress?: string | null,
      frAddress?: string | null,
      rsAddress?: string | null,
      workingHours?: string | null,
      arWorkingHours?: string | null,
      frWorkingHours?: string | null,
      rsWorkingHours?: string | null,
      email: string,
      phone_number: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostCategorySubscriptionVariables = {
  filter?: ModelSubscriptionPostCategoryFilterInput | null,
};

export type OnCreatePostCategorySubscription = {
  onCreatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePostCategorySubscriptionVariables = {
  filter?: ModelSubscriptionPostCategoryFilterInput | null,
};

export type OnUpdatePostCategorySubscription = {
  onUpdatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePostCategorySubscriptionVariables = {
  filter?: ModelSubscriptionPostCategoryFilterInput | null,
};

export type OnDeletePostCategorySubscription = {
  onDeletePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      type: string,
      description?: string | null,
      arDescription?: string | null,
      frDescription?: string | null,
      rsDescription?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      slug: string,
      content?: string | null,
      arContent?: string | null,
      frContent?: string | null,
      rsContent?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
};

export type OnCreateQuizQuestionSubscription = {
  onCreateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
};

export type OnUpdateQuizQuestionSubscription = {
  onUpdateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
};

export type OnDeleteQuizQuestionSubscription = {
  onDeleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      arTitle?: string | null,
      frTitle?: string | null,
      rsTitle?: string | null,
      answer: string,
      arAnswer?: string | null,
      frAnswer?: string | null,
      rsAnswer?: string | null,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        arName?: string | null,
        frName?: string | null,
        rsName?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        propertyGalleryId?: string | null,
        projectExteriorsId?: string | null,
        projectInteriorsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
